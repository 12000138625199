import store from '@/store';
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import {pageHelper} from '@/utils/pageHelper';
import {currentlyInSpaMode} from '@/utils/integrationHelper';
import {checkStoreMethod} from '@/store/check/checkStore';
import errorHandlingRoutes from '@/routes/errorHandlingRoutes';
import loginRoutes from '@/routes/loginRoutes';
import adminRoutes from '@/routes/adminRoutes';
import companyRoutes from '@/routes/companyRoutes';
import checkRoutes from '@/routes/check/checkRoutes';
import {AccessService} from '@/services/accessService';

const routes = [
	...companyRoutes,
	...checkRoutes,
	...loginRoutes,
	...adminRoutes
];

const isCurrentlyInSpaMode = currentlyInSpaMode();
if (isCurrentlyInSpaMode) {
	routes.push(...errorHandlingRoutes());
}

const router = createRouter({
	history: isCurrentlyInSpaMode ? createWebHashHistory(import.meta.env.BASE_URL) : createWebHistory(),
	routes,
	linkActiveClass: 'active',
	linkExactActiveClass: 'exact-active',
	scrollBehavior() {
		document.getElementById('my5xbeterapp').scrollIntoView({behavior: 'smooth'});
	}
});

let initialRender = true;

const clearImprovementCheckError = () => store.commit(checkStoreMethod.CLEAR_ERROR);

router.beforeEach((to, from, next) => {

	clearImprovementCheckError();

	if (isCurrentlyInSpaMode) {
		if (to.matched.some(record => record.meta.roles)) {
			// this route requires auth, check if logged in
			// if not, redirect to login page.
			const loggedIn = store.state.user.isLoggedIn;
			if (!loggedIn) {

				let redirectQuery = null;
				if (!to.meta.noRedirectForLogin) {
					redirectQuery = {redirect: to.fullPath};
				}

				// Parameter doLogin is given when redirected from an anonymous check,
				// authToken is set as cookie, but account data is not loaded yet
				if (to.query.doLogin !== undefined) {
					store.dispatch('checkLoginStatusWithAuthCookie')
						.then(() => {
							next();
						});
					return;
				}

				next({
					name: pageHelper.PAGE_LOGIN,
					query: redirectQuery
				});
				return;
			}

			// Only allow access to route if the user has the required role(s).
			const userCanAccessPromises = Promise.all(to.matched.map(async record =>
				await AccessService.userCanAccess(record.meta?.roles, record.meta?.inviteeRoles, record.meta?.check, record.meta?.rights, to.params.companyId)));

			userCanAccessPromises.then((results) => {
				if (results.every(result => result)) {
					next();
					return;
				}

				next({
					name: pageHelper.PAGE_NO_RIGHTS,
					query: {url: to.fullPath}
				});
			});

			return;
		}
		next();
		return;
	}

	if (initialRender) {
		initialRender = false;
		return;
	}

	// Should never happen when <link-mijn5xbeter> is properly used in the 'generic' parts
	// eslint-disable-next-line
	console.log('vue-router link was clicked, but not in a SPA environment.\rLoading the url with document.location');
});

export default router;
