<template>
	<skeleton-block>
		<div class="block-company">
			<div class="block-company_title">
				<h2><strong>
					<skeleton-text-placeholder length="13" />
				</strong></h2>
			</div>

			<div :key="i" class="block-company_check-status" v-for="i in 6">
				<svg height="30" viewBox="0 0 48 30" width="48">
					<circle cx="30" cy="15" fill="#aaa" r="15" />
					<circle cx="15" cy="15" fill="#000" r="15" />
				</svg>
			</div>
		</div>
	</skeleton-block>
</template>

<script>
	import SkeletonBlock from '../generic/SkeletonBlock';
	import SkeletonTextPlaceholder from '../generic/SkeletonTextPlaceholder';

	export default {
		name: 'SkeletonCompanyListItem',
		components: {SkeletonTextPlaceholder, SkeletonBlock}
	};
</script>
<style lang="scss" scoped>
	@import '@/assets/css/mixins';
	@import '@/assets/css/variables';

	.block-company {
		background: #ffffff;
		padding: 15px 20px;
		margin: 2px 0;
		@include clearfix;
	}

	.block-company_title {
		margin: 0;

		h2 {
			margin: 0 0 12px;
		}
	}

	.block-company_check-status {
		float: left;
		height: 30px;
		line-height: 30px;
		margin: 0 16px 16px 0;

		@media screen and (max-width: 480px) {
			margin-right: 10px;
			margin-bottom: 4px;
		}
	}
</style>
