<template>
	<div class="block-last-header-icon">
		<div class="result-title-icon">
			<icon-check :check="check" />
		</div>
		<icon-status :color="status" />
	</div>
</template>
<script>
	import IconCheck from '@/components/icons/IconCheck';
	import IconStatus from '@/components/icons/IconStatus';
	import {checkHelper, checkStatus, isValidCheckStatus} from '@/utils/checkHelper';

	export default {
		components: {IconStatus, IconCheck},
		props: {
			check: {
				type: String,
				required: true,
				validator: value => checkHelper.isValidCheck(value)
			},
			status: {
				type: String,
				validator: value => isValidCheckStatus(value),
				default: checkStatus.GREY.enum
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.block-last-header-icon {
		float: right;
		position: relative;

		margin: 0;

		.status-icon {

			position: absolute;
			left: -11px;
			bottom: -11px;

			width: 56px;
			height: 56px;
			line-height: 56px ;
			font-size: 24px;

			--status-font-size: 24px;

			.icon-cross {
				font-size: 24px;
			}
			.icon-check {
				font-size: 24px;
			}


			@media #{$mobile} {
				position: relative;
				left: 0;
				bottom: 0;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 9px;
				margin-left: 20px;

				--status-font-size: 8px;

				.icon-cross {
					font-size: 8px;
				}
				.icon-check {
					font-size: 9px;
				}
			}

		}

	}

	.result-title-icon {
		width: 112px;
		height: 112px;
		line-height: 1em;
		font-size: 112px;


		i {
			font-size: 112px;
			line-height: 1em;
			vertical-align: top;
		}

		@media #{$mobile} {
			display: none;
		}
	}
</style>
