<template>
	<router-link :to="backLink" active-class="" class="back-link">
		<em class="icon-chevron-left"></em>&nbsp;
		                                   Terug naar {{ backLinkLabel }}
	</router-link>
</template>
<script lang="ts" setup>
	import {computed} from 'vue';
	import {useRoute} from 'vue-router';
	import {pageHelper} from '@/utils/pageHelper';

	const route = useRoute();

	const backLink = computed(() => {
		// @ts-ignore
		const url : any = route.meta?.backLink?.url;
		if (url && typeof url === 'function') {
			return url(route);
		}
		return {name: url ?? pageHelper.PAGE_MY_COMPANIES};
	});
	// @ts-ignore
	const backLinkLabel = computed(() => route.meta?.backLink?.label ?? 'vorige pagina');
</script>
<style lang="scss" scoped>
	.back-link {
		padding-right: 12px;
	}
</style>
