<template>
	<div class="notice-bar" :class="cssClass">
		<slot></slot>
	</div>
</template>
<script>
	export default {
		name: 'NoticeBar',
		props: {
			cssClass: {
				type: String
			}
		}
	};
</script>
<style lang="scss" scoped>
	.notice-bar {
		margin: 0;
		text-align: center;
		background: #ecdc8e;
		color: #000;
		padding: 4px 1em;
	}
</style>
