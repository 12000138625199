<template>
	<article class="card-block">
		<slot></slot>
	</article>
</template>

<script>
	export default {
		name: 'CardBlock'
	};
</script>

<style lang="scss">
	@import '@/assets/css/variables';

	.card-block {
		background: $color-card-bg;
		padding: 14px 40px 14px 20px;
		margin: 0 0 20px 0;
	}
</style>
