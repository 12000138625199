<template>
	<card-block style="margin-bottom: 2px;">
		<skeleton-block transparent>
			<div class="skeleton-report-visit-summary">
				<div>
					<skeleton-text-placeholder length="12" />
				</div>
				<div>
					<skeleton-text-placeholder length="20" />
				</div>
				<div>
					<skeleton-text-placeholder length="12" />
				</div>
				<div>
					<skeleton-text-placeholder length="24" />
				</div>
				<div :class="compactMode ? '' : 'wide'">
					<skeleton-text-placeholder length="20" />
				</div>
				<div :class="compactMode ? '' : 'wide'">
					<skeleton-text-placeholder length="50" />
				</div>
				<div :class="compactMode ? '' : 'wide'">
					<skeleton-text-placeholder length="22" />
				</div>
				<div :class="compactMode ? '' : 'wide'">
					<skeleton-text-placeholder inline length="18" />
					<skeleton-text-placeholder inline length="12" />
					<skeleton-text-placeholder inline length="20" />
				</div>
			</div>
		</skeleton-block>
	</card-block>
</template>

<script>
	import SkeletonBlock from '../generic/SkeletonBlock';
	import SkeletonTextPlaceholder from '../generic/SkeletonTextPlaceholder';
	import CardBlock from '../generic/CardBlock';

	export default {
		name: 'SkeletonVisitReport',
		components: {CardBlock, SkeletonTextPlaceholder, SkeletonBlock},
		props: {
			compactMode: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style lang="scss" scoped>
	.skeleton-report-visit-summary {
		@media screen and (min-width: 641px) {
			@supports (display: grid) {
				display: grid;
				grid-gap: 0 20px;

				grid-template-columns: auto 1fr;

				div:nth-child(odd) {
					grid-column: 1;
				}

				div {
					grid-column: 2;
				}

				.wide {
					grid-column: 1/3;
				}
			}
		}
	}
</style>
