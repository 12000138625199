<template>
	<table class="admin-company-overview">
		<caption>Bedrijfsoverzicht</caption>
		<thead>
			<tr>
				<th scope="col">Bedrijf</th>
				<th scope="col">Eigenaar</th>
				<th scope="col">E-mailadres eigenaar</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="company in companies"
			    :key="company.id">
				<td>
					<router-link :to="linkCompanyDetail(company.id)">
						{{ company.name }}
					</router-link>

				</td>
				<td>{{ company.ownerName }}</td>
				<td>
					<div :title="company.ownerEmail" class="admin-company-overview__email">
						{{ company.ownerEmail }}
					</div>
				</td>
			</tr>

			<tr v-if="companies.length === 0">
				<td colspan="3">Geen bedrijven gevonden.</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';

	export default {
		name: 'CompanyListTable',
		props: {
			companies: {
				type: Array,
				default: () => []
			}
		},
		methods: {
			linkCompanyDetail(id) {
				return {
					name: pageHelper.PAGE_ADMIN_COMPANY_DETAIL,
					params: {
						companyId: id
					}
				};
			}
		}
	};
</script>

<style lang="scss" scoped>
	.admin-company-overview {
		background: #f7f7f7;
		margin: 0 0 20px;
		width: 100%;
		table-layout: fixed;

		th, td {
			padding-left: 0;
			padding-right: 1em;
			font-size: 12px;

			&:first-child {
				padding-left: 20px;
			}
		}

		thead {
			th {
				font-size: 12px;
			}
		}

		tbody {
			td, th {
				border-top: 2px solid #eeeeee;
				word-break: break-word;
			}
		}

		caption {
			display: none;
		}

		&__email {
			max-width: 14em;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
</style>
