<template>
	<vuelidate-form :is-submitting="isSubmitting"
	                :submit-button-text="showFileUpload ? 'Opslaan' : 'Ga verder'"
	                :vuelidate="v$"
	                :warning-message="warningMessage"
	                cancel-button-text="Annuleren"
	                class="content-form"
	                @cancel="handleCancel"
	                @preValidation="cleanUpBeforeValidation"
	                @validSubmit="handleSubmit">

		<div class="row">
			<div class="col-6 col-sm-12">
				<form-select v-model="v$.form.theme.$model"
				             :validation="v$.form.theme"
				             label="Thema"
				             required>
					<option selected value="">Selecteer een thema</option>
					<option v-for="theme in themeOptions"
					        :key="theme.title"
					        :value="theme.enum">
						{{ theme.title }}
					</option>
				</form-select>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<form-input v-model.trim="v$.form.subject.$model"
				            :validation="v$.form.subject"
				            input-class="input-fullwidth"
				            label="Onderwerp"
				            required />
			</div>
		</div>
		<div v-if="showFileUpload">
			<div class="url-container">
				<h4>Toegevoegde links</h4>

				<div v-if="form.urls.length === 0">Geen links toegevoegd.</div>

				<div v-else class="row row-add-url">
					<div class="col-6">
						<strong>Link</strong>
					</div>
					<div class="col-5">
						<strong>Naam</strong>
					</div>
				</div>

				<div v-for="(v, index) in form.urls" :key="index">
					<form-company-record-url :initial-link="v.link"
							:initial-name="v.name"
							:index="index"
							@delete-link="deleteLinkRow(index)"
							@update-link="updateLinkRow(index, $event)"
					/>
				</div>

				<button class="button button--secondary"
				        type="button"
				        @click="addLinkRow">
					Link toevoegen
				</button>
			</div>

			<h4>Bestanden</h4>
			<div v-if="uploadedFiles.length === 0">Nog geen bestanden toegevoegd.</div>

			<ul v-if="uploadedFiles.length > 0" class="attachment-list">
				<li v-for="file in uploadedFiles"
				    :key="file.name + file.id"
				    class="attachment">
					<a v-if="file.finished && file.url"
					   :download="file.name"
					   :href="prefixWithBackendDomain(file.url)"
					   class="attachment-text"
					   rel="noreferrer noopener"
					   target="_blank">
						{{ file.name }}
					</a>
					<span v-else class="attachment-text">{{ file.name }}</span>

					<button v-if="!file.finished" disabled>
						wordt ge&uuml;pload&hellip;
					</button>
					<button v-if="file.finished"
					        aria-label="Verwijderen"
					        type="button"
					        @click="handleDeleteFile(file)">
						<i class="icon-delete"></i>
					</button>
				</li>
			</ul>

			<label class="button button--secondary upload-btn" for="file-input">
				<input id="file-input"
				       ref="fileInput"
				       hidden
				       name="file-upload"
				       type="file"
				       @change="handleFileUpload">
				Bestand toevoegen
			</label>
		</div>

	</vuelidate-form>
</template>

<script>
	import {themes} from '@/utils/themeHelper';
	import FormSelect from '@/components/forms/FormSelect';
	import VuelidateForm from '@/components/forms/VuelidateForm';
	import FormInput from '@/components/forms/FormInput';
	import {maxLength, required} from '@vuelidate/validators';
	import {Api, prefixWithBackendDomain} from '@/utils/api';
	import {pageHelper} from '@/utils/pageHelper';
	import {useVuelidate} from '@vuelidate/core';
	import FormCompanyRecordUrl from '@/components/companyrecords/FormCompanyRecordUrl.vue';

	export default {
		name: 'FormCompanyRecord',
		components: {FormCompanyRecordUrl, FormInput, VuelidateForm, FormSelect},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			},
			companyRecord: {
				type: Object,
				default: () => ({})
			},
			isNewCompanyRecord: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				showFileUpload: false,
				uploadedFiles: [],
				form: {
					theme: '',
					subject: '',
					urls: []
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations: {
			form: {
				theme: {
					required
				},
				subject: {
					required,
					maxLength: maxLength(255)
				},
				urls: {
				}
			}
		},
		computed: {
			themeOptions() {
				return themes;
			}
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';

				if (this.isNewCompanyRecord && !this.showFileUpload) {
					await this.submitCompanyRecord();
					return;
				}
				await this.updateCompanyRecord();
			},

			async submitCompanyRecord() {
				await Api.postAddCompanyRecord(this.companyId, this.form)
					.then((response) => {
						this.showFileUpload = true;

						this.$router.push({
							name: pageHelper.PAGE_COMPANY_RECORDS_EDIT,
							params: {
								companyRecordId: response.responseObject.id,
								companyId: this.companyId,
								companyRecord: response.responseObject,
								isNewCompanyRecord: false
							}
						});
					})
					.catch((error) => {
						this.handleResponseError(error, 'Item kon niet worden opgeslagen');
					}).finally(() => {
						this.isSubmitting = false;
					});
			},

			async updateCompanyRecord() {
				await Api.postUpdateCompanyRecord(this.companyId, this.companyRecord.id, this.form)
					.then(() => {
						this.$emit('submitted');
					}).catch((error) => {
						this.handleResponseError(error, 'Item kon niet worden opgeslagen');
					});
			},

			async handleFileUpload(event) {
				this.warningMessage = '';
				if (!event.target?.files) {
					return;
				}

				const fileToUpload = event.target.files[0];
				this.$refs.fileInput.value = null;

				// Max 100MB
				const maxFileSize = 100 * 1024 * 1024;
				if (fileToUpload.size > maxFileSize) {
					this.warningMessage = 'Bestand is te groot. Max 100MB';
					return;
				}

				const index = this.uploadedFiles.push({name: fileToUpload.name}) - 1;

				const body = new FormData();
				body.append('file', fileToUpload);

				await Api.postUploadCompanyRecordFile(this.companyId, this.companyRecord.id, body)
					.then((response) => {
						const uploadedFile = response.responseObject;
						this.uploadedFiles[index] = {...uploadedFile, finished: true};
					})
					.catch((error) => {
						this.uploadedFiles.splice(index, 1);
						this.handleResponseError(error, 'Bestand kon niet worden geüpload');
					});
			},

			async handleDeleteFile(file) {
				this.warningMessage = '';

				await this.$confirmDialog({
						title: 'Bijlage verwijderen',
						content: `Weet je zeker dat je deze bijlage wilt verwijderen? Dit bestand zal niet meer op te vragen zijn.`,
						confirmButton: 'Ja, verwijder deze bijlage',
						dialogType: 'warning'
					})
					.then(async () => {
						await Api.deleteCompanyRecordFile(this.companyId, this.companyRecord.id, file.id)
							.then(() => {
								const index = this.uploadedFiles.indexOf(file);

								if (index > -1) {
									this.uploadedFiles.splice(index, 1);
								}
							})
							.catch((error) => {
								this.handleResponseError(error, 'Bestand kon niet worden verwijderd');
							});
					})
					.catch(() => {
						// Canceled, do nothing
					});
			},

			handleResponseError(error, message) {
				if (error.message && error.message.length > 0) {
					this.warningMessage = error.message;
					return;
				}
				this.warningMessage = message;
			},

			async handleCancel() {
				if (this.isNewCompanyRecord && this.companyRecord?.id) {
					await Api.deleteCompanyRecord(this.companyId, this.companyRecord.id);
				}

				this.$emit('cancel');
			},

			initForm() {
				if (!this.isNewCompanyRecord) {
					this.form = {...this.form, ...this.companyRecord};
					this.uploadedFiles = this.companyRecord.files || [];
					this.uploadedFiles.forEach(file => file.finished = true);
					this.showFileUpload = true;
				}
			},

			addLinkRow() {
				this.form.urls.push({name: '', link: ''});
			},

			deleteLinkRow(index) {
				this.form.urls.splice(index, 1);
			},

			updateLinkRow(index, link) {
				this.form.urls[index] = link;
			},

			cleanUpBeforeValidation() {
				// Remove empty 'url' fields, so they won't be submitted
				this.form.urls = this.form.urls.filter(url => url.link.length > 0 || url.name.length > 0);
			},
			prefixWithBackendDomain
		},
		mounted() {
			this.initForm();
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.url-container {
		margin: 2em 0;
	}

	.row-add-url {
		.button {
			margin: 0;
			@media screen and (min-width: 1025px) {
				width: 100%;
				padding-left: 1em;
				padding-right: 1em;
			}
		}
	}

	.attachment {
		width: 100%;
		display: flex;
		align-items: center;
		background: #ffffff;
		padding: .5em 40px .5em 1em;
		margin: 0 0 2px;
		position: relative;
		min-height: 40px;

		&:last-child {
			margin-bottom: 0;
		}

		button {
			min-width: 40px;
			right: 0;
			bottom: 0;
			top: 0;
			border: none;
			color: $blue;
			border-left: 2px solid $color-card-bg;
			background: none;
			margin-left: auto;
			position: absolute;

			&:hover,
			&:focus {
				background: $color-card-bg-hover;
				color: #007dac;
			}

			&[disabled] {
				color: inherit;
			}
		}
	}

	.attachment-list {
		margin: .5em 0 1em;
		padding: 0;
	}

	.attachment-text {
		margin-right: 5px;
	}

	.icon-cross {
		color: $blue;
		cursor: pointer;
	}
</style>
