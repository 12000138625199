<!-- This component is used as local mock to replace the actual header from the ImprovementCheck app -->
<template>
	<div v-if="showHeader" id="header-main" class="header-fixed-container">
		<header>
			<div class="header-container">
				<button class="header-toggle-leftmenu js-toggle-leftmenu show-when-offcanvasmenu-is-active">
					<i class="icon-hamburger"></i>
					<i class="icon-close"></i>
				</button>

				<div class="header-logo">
					<a href="/site">
						<img alt="5xbeter"
						     src="../../assets/logo-5xbeter.png"
						     srcset="../../assets/logo-5xbeter.svg" />
					</a>
				</div>

				<button class="header-toggle-rightmenu js-toggle-rightmenu show-when-offcanvasmenu-is-active">
					<i class="icon-hamburger-user"></i>
					<i class="icon-close"></i>
				</button>

				<div class="header-nav hide-when-offcanvasmenu-is-active">
					<vijfxbeter-header-menu-items />
				</div>

				<div class="header-nav right hide-when-offcanvasmenu-is-active" >
					<vijfxbeter-account />
				</div>
			</div>
		</header>
	</div>
</template>

<script>
	import VijfxbeterHeaderMenuItems from '@/components/wrappers/VijfxbeterHeaderMenuItems.vue';

	export default {
		name: 'vijfxbeter-header',
		components: {VijfxbeterHeaderMenuItems},

		computed: {
			showHeader() {
				return !this.$route.meta.improvementCheckFullScreen ?? true;
			}
		}

	};
</script>
<style lang="scss" >
	@import "@/assets/css/_variables.scss";
	@import "@/assets/css/_mixins.scss";
	@import "@/assets/css/_global.scss";
	@import "@/assets/css/global/_icons-mijn5xbeter.scss";
	@import "@/assets/css/website/_offcanvas.scss";
	@import "@/assets/css/website/_header.scss";
	@import "@/assets/css/website/_header-search.scss";
</style>
