<template>
	<form :class="isSubmitting ? 'vuelidate-form--submitting' : ''"
	      @mouseleave="$emit('mouseleave')"
	      @submit="validateAndSubmitForm"
	      class="vuelidate-form"
	      novalidate
	      ref="form">
		<slot></slot>

		<form-warning-block :message="clientSideValidationMessage" v-if="clientSideValidationMessage" />

		<form-warning-block :message="warningMessage" v-if="warningMessage" />

		<div class="button-bar" v-if="!hideButtonBar">
			<slot name="button-bar">
				<button :disabled="isSubmitting" class="button" type="submit">{{ submitButtonText }}</button>
				<button :disabled="isSubmitting"
				        @click="$emit('cancel')"
				        class="button button-no-border"
				        type="button"
				        v-if="cancelButtonText.length > 0">
					{{ cancelButtonText }}
				</button>
			</slot>
		</div>

		<slot name="after-button-bar"></slot>
	</form>
</template>

<script>

	import FormWarningBlock from './FormWarningBlock';

	export default {
		name: 'VuelidateForm',
		components: {FormWarningBlock},
		props: {
			vuelidate: {
				type: Object,
				required: true
			},
			hideButtonBar: {
				type: Boolean,
				default: false
			},
			isSubmitting: {
				type: Boolean,
				default: false
			},
			submitButtonText: {
				type: String,
				default: 'Verzend'
			},
			cancelButtonText: {
				type: String,
				default: ''
			},
			warningMessage: {
				type: String,
				default: ''
			}
		},
		data() {
			return {};
		},
		computed: {
			clientSideValidationMessage() {
				if (this.vuelidate.$invalid && this.vuelidate.$dirty) {
					return 'Niet alle velden zijn correct ingevuld';
				}

				return null;
			}
		},
		methods: {
			validateAndSubmitForm(event) {
				event.preventDefault();
				this.$emit('preValidation');
				this.vuelidate.$touch();
				if (this.vuelidate.$invalid) {
					return;
				}

				this.$emit('validSubmit', event);

			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/css/global/_buttons.scss';
</style>
