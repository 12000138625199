import * as Sentry from '@sentry/vue';
import {App} from 'vue';
import type {Router} from 'vue-router';
import * as process from 'process';

const SENTRY_DSN = process.env?.VITE_APP_SENTRY_DSN ?? '';
const SENTRY_ENVIRONMENT: string = process.env?.VITE_APP_SENTRY_ENVIRONMENT ?? '';
const RELEASE_VERSION: string = `${process.env?.npm_package_name}-${process.env?.npm_package_version}`;
const SENTRY_URL: string = 'https://sentry.eleven.nl';
export const sentryEnabled = () => typeof SENTRY_DSN === 'string' && SENTRY_DSN.length > 0;

export const getSentryPluginOptions = (buildDir: string) => ({
	org: 'eleven',
	project: 'vijfxbeter-mijnomgeving-frontend',
	release: {
		name: RELEASE_VERSION,
		dist: process.env.npm_package_name
	},
	url: SENTRY_URL,
	include: buildDir,
	urlPrefix: '~/frontend-build/assets',
	authToken: process.env.SENTRY_AUTH_TOKEN
});

export const initSentry = (Vue: App<Element>, router: Router) => {
	if (!sentryEnabled()) {
		console.warn('No Sentry DSN defined');
		return;
	}

	Sentry.init({
		Vue,
		dsn: SENTRY_DSN,
		environment: SENTRY_ENVIRONMENT,
		release: RELEASE_VERSION,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router)
			})
		],
		tracesSampleRate: 1.0
	});
};

