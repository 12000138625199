<template>
	<div class="checkbox-list-item" :class="`checkbox-list-item--checkbox-${checkboxPosition}`">
		<input :disabled="disabled"
		       :id="inputId"
		       :name="name"
		       :required="required"
		       :checked="shouldBeChecked"
		       :value="value"
		       @change="updateInput"
		       type="checkbox">
		<label :for="inputId">{{label}}</label>
	</div>
</template>

<script>
	import {createUniqueId} from '@/utils/uid';

	export default {
		name: 'CheckboxListItem',
		props: {
			value: {
				type: String,
				default: ''
			},
			modelValue: {
				default: false
			},
			trueValue: {
				default: true
			},
			falseValue: {
				default: false
			},
			label: {
				type: String,
				required: true
			},
			required: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			id: {
				type: String
			},
			name: {
				type: String
			},
			checkboxPosition: {
				type: String,
				default: 'left'
			}
		},
		data() {
			return {
				uid: createUniqueId()
			};
		},
		computed: {
			inputId() {
				return this.id ?? `input-id-${this.uid}`;
			},
			shouldBeChecked() {
				if (this.modelValue instanceof Array) {
					return this.modelValue.includes(this.value);
				}
				// Note that `true-value` and `false-value` are camelCase in the JS
				return this.modelValue === this.trueValue;
			}
		},
		methods: {
			updateInput(event) {
				const isChecked = event.target.checked;
				this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.checkbox-list-item.checkbox-list-item--checkbox-left {
		label {
			user-select: none;
			padding-left: 38px;

			&::before {
				position: absolute;
				left: 10px;
				top: 10px;
				margin: 0;
			}
		}
	}
</style>
