<template>
    <dialog-container :show="show"
                      @close="closeDialog"
                      title="Advies toevoegen"
                      width="600">
        <div class="popup-content" id="modal-add-advice">
            <form-advice :companyId="companyId"
                         :theme="theme"
                         @submitted="closeDialog"
                         @cancel="closeDialog"
                         new-advice />
        </div>
    </dialog-container>
</template>

<script>
    import DialogContainer from '../../plugins/confirmDialog/DialogContainer';
    import FormAdvice from '@/components/advice/FormAdvice';

    export default {
        name: 'ModalAddAdvice',
        components: {FormAdvice, DialogContainer},
        computed: {
            show() {
                return this.$store.state.modals.addAdvice.show;
            },
            companyId() {
                return this.$store.state.modals.addAdvice.companyId;
            },
            theme() {
                return this.$store.state.modals.addAdvice.theme;
            }
        },
        methods: {
            closeDialog() {
                this.$store.dispatch('modals/addAdvice/closeDialog');
            }
        }
    };
</script>

<style lang="scss" scoped>
    #modal-add-advice {
        :deep(textarea) {
            width: 100%;
        }
    }
</style>
