<template>
	<div v-if="htmlContent.length > 0" v-html="htmlContent">
	</div>
	<vijfxbeter-footer-fallback v-else />
</template>

<script>

	import VijfxbeterFooterFallback from '@/components/wrappers/VijfxbeterFooterFallback.vue';

	const footerUrl = import.meta.env.VITE_APP_WEBSITE_DOMAIN + '/verbetercheck/footer';
	export default {
		name: 'vijfxbeter-footer',
		components: {VijfxbeterFooterFallback},
		data() {
			return {
				htmlContent: '',
				footerUrl
			};
		},
		methods: {
			fetchFooter() {
				return fetch(footerUrl)
					.then((response) => {
						if (!response.ok) {
							throw new Error('HTTP error ' + response.status);
						}
						return response.text();
					})
					.then(text => this.htmlContent = text)
					.catch((error) => {
						console.error('There has been a problem while fetching the footer:', error);
						this.htmlContent = this.fallbackHtml;
						const oneMinuteRetry = 60000;
						setTimeout(() => this.fetchFooter(), oneMinuteRetry);
					});
			}
		},
		mounted() {
			this.fetchFooter();
		}
	};
</script>


