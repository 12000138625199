<template>
	<div :style="{backgroundColor: transparent ? 'transparent' : ''}" aria-hidden="true" class="skeleton-block">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'SkeletonBlock',
		props: {
			transparent: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/css/variables';

	$opacity-skeleton-block: .5;

	.skeleton-block {
		background: #ffffff;
		margin: 0 0 2px;
		overflow: hidden;
		position: relative;
		opacity: $opacity-skeleton-block;
		user-select: none;
		cursor: wait;
		animation: skeletonBlockFadeIn .5s ease-in-out;

		& > :deep(*),
		& :deep(button) {
			opacity: .33;
			pointer-events: none !important;
		}

		::after {
			content: '';
			opacity: .5;
			background: #ffffff;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%);
			position: absolute;
			z-index: 9999;
			top: 0;
			left: 0;
			bottom: 0;
			width: 33%;
			transform: skew(-45deg);
			animation: skeletonLoadingOverlay 1.25s ease-in-out infinite;
		}

		@keyframes skeletonBlockFadeIn {
			0% {
				opacity: 0;
			}
			25% {
				opacity: 0;
			}
			100% {
				opacity: $opacity-skeleton-block;
			}
		}

		@keyframes skeletonLoadingOverlay {
			0% {
				transform: translate3d(-100%, 0, 0) skew(-20deg);
			}
			100% {
				transform: translate3d(300%, 0, 0) skew(-20deg);
			}
		}
	}
</style>
