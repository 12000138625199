<template>
	<div class="row row-add-url">
		<div class="col-6">
			<form-input v-model.trim="v$.link.$model.toString"
			            :validation="v$.link"
			            hide-label
			            input-class="input-fullwidth"
			            label="Link"
			            required
			            @input="$emit('update-link', { name, link })"/>
		</div>
		<div class="col-5">
			<form-input v-model.trim="v$.name.$model.toString"
			            :validation="v$.name"
			            hide-label
			            input-class="input-fullwidth"
			            label="Naam"
			            required
			            @input="$emit('update-link', { name, link })"/>
		</div>
		<div class="col-1 col-md-2">
			<button aria-label="Verwijderen"
			        class="button button-no-border"
			        type="button"
			        @click="$emit('delete-link')">
				<i class="icon-delete"></i>
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
	import {useVuelidate} from '@vuelidate/core';
	import {requiredIf, url} from '@vuelidate/validators';
	import {computed, onMounted, ref, Ref} from 'vue';
	import FormInput from '@/components/forms/FormInput.vue';

	interface Props {
		initialLink: string;
		initialName: string;
		index: number;
	}

	const props = defineProps<Props>();

	const name: Ref = ref();
	const link: Ref = ref();

	const rules = computed(() => ({
		link: {
			requiredIfRef: requiredIf(name),
			url
		},
		name: {
			requiredIfRef: requiredIf(link)
		}
	}));
	const v$ = useVuelidate(rules, { link, name });

	onMounted(() => {
		name.value = props.initialName;
		link.value = props.initialLink;
	});
</script>
