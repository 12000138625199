<template>
	<div class="header-white">
		<div class="header-container">
			<ul class="header-breadcrumb">
				<li>
					<router-link to="/">{{ homeTitle }}</router-link>
					<span class="separator" v-if="items.length > 0"></span>
				</li>
				<li v-for="(item, index) in items" :key="index">
					<template v-if="(index + 1) === items.length">
						{{ item.text }}
					</template>
					<template v-else>
						<router-link :to="item.to">{{ item.text }}</router-link>
						<span class="separator"></span>
					</template>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'BreadCrumbs',
		props: {
			items: {
				type: Array,
				default: () => []
			}
		},

		data() {
			return {
				homeTitle: import.meta.env.VITE_APP_TITLE
			};
		},

		watch: {
			items: {
				immediate: true,
				handler(items) {
					const title = items.map(item => item.text).join(' - ');
					document.title = `5xbeter - ${title.length > 0 ? title : 'Mijn omgeving'}`;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.header-white {
		background: #fff;
	}

	.header-container {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		padding: 5px 20px;
		min-height: 50px;
	}

	.header-breadcrumb {
		color: #81786e;
		margin: 0;
		font-size: 12px;
		line-height: 20px;
		padding: 10px 0;
	}

	.header-breadcrumb li {
		display: inline-block;
	}

	.header-breadcrumb li a {
		color: #81786e;
		text-decoration: none;
	}

	.header-breadcrumb span.separator {
		color: #81786e;
	}

	.header-breadcrumb span.separator::before {
		content: '>';
		padding: 0 4px;
	}
</style>
