<template>
	<check-result :check-sections="checkSections"
	              :check-status="result.status"
	              :last-updated="result.lastUpdated"
	              :last-updated-by="result.lastUpdatedBy"
	              :loading="loading"
	              :page-title="`Verbetercheck - ${ result.name }`">

		<template v-if="loading">
			<skeleton-card-block-result small />
			<skeleton-card-block-result />
		</template>

		<template v-if="!loading && result.scripts.length > 0">

			<div v-if="result.status && !(result.status === checkStatus.GREY.enum || result.status === checkStatus.WHITE.enum)"
			     class="button-bar">
				<button class="button" @click="downloadImprovementPlanPdfUrl">
					<em class="icon-download"></em>
					Plan van Aanpak
				</button>
				<!--TODO VXBHERFYSB-29-->
				<!--				<a href="#" class="button">
								<span class="button_maintext"><em class="icon-download"></em>
										Download rapport
									</span>
								</a>-->
				<!--TODO VXBHERFYSB-32-->
				<!--				<a href="#" class="button">
									<em class="icon-help"></em>
									Hulp vragen
								</a>-->
			</div>

			<card-block-result v-if="functionGroupId" :has-status="false" title="Algemene informatie over functiegroep">

				<div class="general-information">
					<div class="text-grey">Naam</div>
					<div>{{ result.name }}</div>

					<div class="text-grey">Handelingen</div>
					<div v-if="result.actions.length > 0">
						<ul class="actions">
							<li v-for="action in result.actions" :key="action">{{ action }}</li>
						</ul>
					</div>
					<div v-else>Er zijn geen handelingen toegevoegd.</div>
				</div>

				<template v-slot:content>
					<card-block-result-content v-if="hasWriteAccess">
						<router-link :to="goToFunctionGroupEditPage()" class="button button-no-border">
							<em class="icon-edit"></em>
							Naam aanpassen
						</router-link>
						<router-link :to="goToChangeActionsPage()" class="button button-no-border">
							<em class="icon-edit"></em>
							Handelingen aanpassen
						</router-link>
					</card-block-result-content>
				</template>

			</card-block-result>

			<card-block-result v-for="script in processedScripts"
			                   :key="script.scriptKey"
			                   :status="script.status"
			                   :title="script.name">

				<div v-if="script.status === 'GREY'" class="text-grey">
					Dit onderdeel is nog niet afgerond
				</div>
				<div v-else-if="script.risks.length === 0" class="text-grey">
					Er zijn geen risico's voor dit onderdeel naar voren gekomen
				</div>
				<template v-else>
					<h4 class="text-red">Knelpunten met een risico</h4>
					<ul>
						<li v-for="(risk, index) in script.risks"
						    :key="index"
						    class="text-red"
						    v-html="risk.label">
						</li>
					</ul>
				</template>

				<template v-slot:content>
					<div v-for="script in script.scriptKeys"
					     :key="script.scriptKey">

						<card-block-result-content v-if="script.scriptRemarks">
							<div class="text-grey">Opmerkingen</div>
							<div>{{ script.scriptRemarks }}</div>
						</card-block-result-content>
					</div>

					<card-block-result-content v-if="showFactSheetsProperty(script.scriptKey)">
						<h4>Factsheets</h4>
						<a :href="factSheetURL" target="_blank">
							<em class="icon-chevron-right"></em>
							Bekijk de factsheets
						</a>
					</card-block-result-content>

					<card-block-result-content>
						<div v-for="script in script.scriptKeys"
						     :key="script.scriptKey">

							<router-link v-if="isChangeAnswer(script)"
							             :to="goToChangeAnswerPage(script)"
							             class="button button-no-border">
								<em class="icon-edit"></em>
								{{ changeAnswerLinkText(script, true) }}
							</router-link>

							<router-link v-else-if="isResumeCheck(script)"
							             :to="goToResumeCheckPage(script)"
							             class="button button-no-border">
								<em class="icon-edit"></em>
								{{ resumeAnswerLinkText(script, true) }}
							</router-link>
						</div>
					</card-block-result-content>

				</template>
			</card-block-result>

			<button v-if="functionGroupId" :class="{'disabled': !hasWriteAccess}" class="add-button" type="button"
			        @click="addAction">
				<em class="icon-plus"></em>
				Nieuwe handeling toevoegen
			</button>
		</template>

	</check-result>
</template>

<script>
	import {PhysicalStrainApi} from '@/components/check/physicalstrain/physicalApi';
	import CardBlockResult from '@/components/check/results/CardBlockResult';
	import CardBlockResultContent from '@/components/check/results/CardBlockResultContent';
	import SkeletonCardBlockResult from '@/components/check/results/SkeletonCardBlockResult';
	import {
		getGenericScriptTitle,
		isLiftingScript,
		physicalScriptKey
	} from '@/components/check/physicalstrain/physicalScriptKeys';
	import {checkStatus, shouldUpdateStatus} from '@/utils/checkHelper';
	import {pageHelper} from '@/utils/pageHelper';
	import {checkStoreMethod} from '@/store/check/checkStore';
	import CheckResult from '@/components/check/results/CheckResult';
	import {rightsHelper} from '@/utils/rightsHelper';
	import NumberUtils from '@/utils/numberUtils';

	export default {
		name: 'PageResultsPhysicalFunctionGroup',
		components: {
			CheckResult,
			SkeletonCardBlockResult,
			CardBlockResultContent,
			CardBlockResult
		},
		props: {
			checkSections: {
				type: Array,
				default: () => []
			}
		},
		data: () => ({
			loading: false,
			result: {
				id: null,
				lastUpdated: '',
				lastUpdatedBy: '',
				name: '',
				status: '',
				scripts: []
			}
		}),
		computed: {
			checkStatus() {
				return checkStatus;
			},
			companyId() {
				return NumberUtils.stringToNumber(this.$route.params.companyId);
			},
			/**
			 * If functionGroupIdOrScriptKey can be parsed to an Int, it's a functionGroupId.
			 * Used to retrieve the result for a function group from the back-end.
			 */
			functionGroupId() {
				return NumberUtils.stringToNumber(this.$route.params.functionGroupIdOrScriptKey);
			},
			/**
			 * To re-use this component for generic scripts, the scriptKey is used to retrieve the result
			 * from the back-end.
			 */
			scriptKey() {
				return !this.functionGroupId ? this.$route.params.functionGroupIdOrScriptKey : null;
			},
			hasWriteAccess() {
				return rightsHelper.hasWriteAccess(this.$store.getters.readWriteAccess(this.companyId));
			},
			factSheetURL() {
				return `${import.meta.env.VITE_APP_WEBSITE_DOMAIN}/site/nl/themas/fysieke-belasting/hulpmiddelen`;
			},
			processedScripts() {
				// Make a copy with only the applicable scripts.
				const applicableScripts = [...this.result.scripts.filter(script => checkStatus.WHITE.enum !== script.status)];

				// Adding all scripts to scriptKeys as array, this way we can handle all scripts
				// in the same way as liftingScripts.
				applicableScripts.forEach((value) => {
					if (!value.scriptKeys) {
						value.scriptKeys = [];
					}
					value.scriptKeys.push(value);
				});

				// Merge the lifting scripts if required.
				if (applicableScripts.some(script => isLiftingScript(script.scriptKey))) {
					return this.mergeLiftingScripts(applicableScripts);
				}
				return applicableScripts;
			}
		},
		methods: {
			downloadImprovementPlanPdfUrl() {
				if (this.scriptKey !== null) {
					const url = PhysicalStrainApi.getPhysicalStrainPdfForScript(this.companyId, this.scriptKey);
					PhysicalStrainApi.downloadPdf(url);
					return;
				}
				if (this.functionGroupId !== null) {
					const url = PhysicalStrainApi.getPhysicalStrainPdfForFunctionGroupUrl(this.companyId, this.functionGroupId);
					PhysicalStrainApi.downloadPdf(url);
					return;
				}

				this.$store.dispatch(checkStoreMethod.SET_WARNING_TOP, 'Het plan van aanpak kan niet gedownload worden.');
			},

			async getResults() {
				this.loading = true;

				if (this.functionGroupId) {
					await this.getResultsForFunctionGroup(this.companyId, this.functionGroupId);
				} else {
					await this.getResultsForScript(this.scriptKey);
				}
			},

			async getResultsForFunctionGroup() {
				await PhysicalStrainApi.getPhysicalStrainResultsForFunctionGroup(this.companyId, this.functionGroupId)
					.then(response => this.result = response.responseObject)
					.catch(() => this.$store.dispatch(checkStoreMethod.SET_WARNING, 'Resultaten konden niet worden geladen.'))
					.finally(() => this.loading = false);
			},

			async getResultsForScript() {
				await PhysicalStrainApi.getPhysicalStrainResultsForScript(this.companyId, this.scriptKey)
					.then((response) => {
						const responseObject = response.responseObject;
						this.result.name = getGenericScriptTitle(this.scriptKey);
						this.result.lastUpdated = responseObject.lastUpdated;
						this.result.lastUpdatedBy = responseObject.lastUpdatedBy;
						this.result.status = responseObject.status;
						this.result.scripts = [responseObject];
					})
					.catch(() => this.$store.dispatch(checkStoreMethod.SET_WARNING, 'Resultaten konden niet worden geladen.'))
					.finally(() => this.loading = false);
			},

			async addAction() {
				if (this.hasWriteAccess) {
					await this.$router.push(this.goToChangeActionsPage());
				}
			},

			isChangeAnswer(script) {
				return checkStatus.GREY.enum !== script.status;
			},

			isResumeCheck(script) {
				return checkStatus.GREY.enum === script.status && this.hasWriteAccess;
			},

			changeAnswerLinkText(script, includeScriptName) {
				let text = 'Antwoorden ' + (this.hasWriteAccess ? 'aanpassen' : 'inzien');

				if (includeScriptName) {
					text += ' voor ' + script.name;
				}

				return text;
			},

			resumeAnswerLinkText(script, includeScriptName) {
				let text = 'Check vervolgen';

				if (includeScriptName) {
					text += ' voor ' + script.name;
				}

				return text;
			},

			mergeLiftingScripts(scripts) {
				const mergedLiftingScript = {
					name: 'Tillen',
					risks: [],
					scriptKey: null,
					scriptKeys: [],
					status: checkStatus.WHITE.enum
				};

				let indexToAppend = 0;

				// Bundle the singular lifting scripts into one object
				scripts.forEach((script, index) => {
					if (isLiftingScript(script.scriptKey)) {
						indexToAppend = index;
						mergedLiftingScript.risks = [...mergedLiftingScript.risks, ...script.risks];
						mergedLiftingScript.scriptKeys.push(script);

						if (shouldUpdateStatus(mergedLiftingScript.status, script.status)) {
							mergedLiftingScript.status = script.status;
						}
					}
				});

				// Add the bundled liftingScript somewhere around the singular lifting scripts
				scripts.splice(indexToAppend, 0, mergedLiftingScript);

				return scripts.filter(script => !isLiftingScript(script.scriptKey));
			},

			showFactSheetsProperty(scriptKey) {
				return scriptKey !== physicalScriptKey.education;
			},

			goToChangeAnswerPage(script) {
				const query = {
					scriptKey: script.scriptKey
				};

				if (this.functionGroupId) {
					query.functionGroupId = this.functionGroupId;
				}

				return {
					name: pageHelper.PAGE_PHYSICAL_ANSWERS_OVERVIEW,
					params: {
						companyId: this.companyId
					},
					query
				};
			},

			goToChangeActionsPage() {
				const query = {
					scriptKey: physicalScriptKey.actionsQuestion,
					functionGroupId: this.functionGroupId
				};

				return {
					name: pageHelper.PAGE_CHECK_PHYSICAL_STRAIN,
					params: {
						companyId: this.companyId
					},
					query
				};
			},

			goToResumeCheckPage(script) {
				const query = {
					scriptKey: script.scriptKey,
					resume: true
				};

				if (this.functionGroupId) {
					query.functionGroupId = this.functionGroupId;
				}

				return {
					name: pageHelper.PAGE_CHECK_PHYSICAL_STRAIN,
					params: {
						companyId: this.companyId
					},
					query
				};
			},

			goToFunctionGroupEditPage() {
				return {
					name: pageHelper.PAGE_PHYSICAL_FUNCTION_GROUP,
					params: {
						companyId: this.companyId
					}
				};
			}
		},
		watch: {
			companyId() {
				this.getResults();
			},
			functionGroupId() {
				if (this.functionGroupId) {
					this.getResults();
				}
			}
		},
		mounted() {
			this.getResults();
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.general-information {
		display: grid;
		grid-template-columns: 33% 67%;
	}

	.actions {
		padding: 0;

		& li {
			list-style: none;
		}
	}

	.add-button {
		margin: 0;

		&.disabled .icon-plus {
			border-right-color: $blue;
		}
	}
</style>
