<template>
	<vuelidate-form :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                @validSubmit="handleSubmit"
	                class="content-form"
	                hide-button-bar>

		<div class="block-form">
			<div class="row" v-if="!resetPassword">
				<div class="col-6 col-sm-12">
					<form-input :validation="v$.form.oldPassword"
					            autocomplete="current-password"
					            label="Oud wachtwoord"
					            maxlength="255"
					            name="oldPassword"
					            placeholder="Oud wachtwoord"
					            required
					            type="password"
					            v-model.trim="v$.form.oldPassword.$model"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-6 col-sm-12">
					<form-input :validation="v$.form.newPassword"
					            autocomplete="new-password"
					            label="Nieuw wachtwoord"
					            maxlength="255"
					            minlength="8"
					            name="newPassword"
					            placeholder="Nieuw wachtwoord"
					            required
					            type="password"
					            v-model.trim="v$.form.newPassword.$model" />
				</div>
				<div class="col-6 col-sm-12">
					<p class="form-explanation">
						<strong>Let op:</strong>
						je wachtwoord moet bestaan uit minimaal acht karakters,
						waarvan minimaal één kleine letter, één hoofdletter en één cijfer.
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col-6 col-sm-12">
					<form-input :validation="v$.form.repeatPassword"
					            autocomplete="new-password"
					            label="Herhaal nieuw wachtwoord"
					            maxlength="255"
					            minlength="8"
					            name="repeatPassword"
					            placeholder="Nieuw wachtwoord"
					            required
					            type="password"
					            v-model.trim="v$.form.repeatPassword.$model" />
				</div>
			</div>
		</div>

		<form-warning-block :message="warningMessage" v-if="warningMessage" />

		<div class="block-form-buttons">
			<div class="row">
				<div class="col-6 col-sm-12">
					<button class="button" type="submit">Opslaan</button>
				</div>

				<div class="col-6 col-sm-12">
					<p class="text-right text-grey">
						Velden met * zijn verplicht
					</p>
				</div>
			</div>
		</div>
	</vuelidate-form>

</template>

<script>
	import {Api} from '@/utils/api';
	import FormWarningBlock from '../forms/FormWarningBlock';
	import {maxLength, minLength, required, sameAs} from '@vuelidate/validators';
	import VuelidateForm from '../forms/VuelidateForm';
	import FormInput from '../forms/FormInput';
	import {pageHelper} from '@/utils/pageHelper';
	import {password} from '@/utils/customValidators';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormUpdatePassword',
		components: {VuelidateForm, FormWarningBlock, FormInput},
		props: {
			formMode: {
				type: String,
				default: 'header'
			},
			resetPassword: {
				type: Boolean,
				default: false
			},
			token: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				form: {
					oldPassword: '',
					newPassword: '',
					repeatPassword: '',
					token: ''
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations() {
			const validation = {
				form: {
					newPassword: {
						required,
						minLength: minLength(8),
						maxLength: maxLength(255),
						password
					},
					repeatPassword: {
						sameAsPassword: sameAs(this.form.newPassword)
					}
				}
			};

			if (!this.resetPassword) {
				validation.form.oldPassword = {
					required
				};
			} else {
				validation.form.token = {
					required
				};
			}
			return validation;
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				await this.getApiCall()()
					.then(() => {
						this.$router.push({
							name: pageHelper.PAGE_MY_COMPANIES
						});
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Wachtwoord wijzgigen is niet gelukt';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			getApiCall() {
				if (this.resetPassword) {
					return () => Api.postResetPassword(this.form, this.token);
				}
				return () => Api.postUpdatePassword(this.form);
			}
		},
		mounted() {
			this.form.token = this.token;
		}
	};
</script>
