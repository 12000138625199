<template>
	<base-grid>

		<template v-slot:sidebar>
			<sidebar-physical-strain :function-groups="checkSections" :loading="loading" />
		</template>

		<result-page-header v-if="!loading"
		                    :check="checkEnum"
		                    :loading="loading"
		                    :show-invite="isCompanyOwner"
		                    :status="checkStatus"
		                    :title="pageTitle"
		                    @showInvitationModal="showInvitationModal = true">

			<template v-if="lastUpdatedDate" v-slot:last-edited>
				Laatste wijziging op <strong>{{ lastUpdatedDate }}</strong> door
				<strong>{{ lastUpdatedBy }}</strong>
			</template>
		</result-page-header>

		<slot></slot>

		<modal-invitation v-if="isCompanyOwner"
		                  :coachInvited="hasCoachInvited"
		                  :company-id="Number(companyId)"
		                  :show="showInvitationModal"
		                  @close="showInvitationModal = false" />

	</base-grid>
</template>

<script lang="ts" setup>
	import BaseGrid from '@/components/decorations/BaseGrid.vue';
	import SidebarPhysicalStrain from '@/components/check/physicalstrain/SidebarPhysicalStrain.vue';
	import ModalInvitation from '@/components/company/ModalInvitation.vue';
	import ResultPageHeader from '@/components/check/ResultPageHeader.vue';
	import {getFormattedDate} from '@/utils/date';
	import {computed, ref, Ref} from 'vue';
	import {useRoute} from 'vue-router';
	import store from '@/store';
	import NumberUtils from '@/utils/numberUtils';
	import {ICheckSectionResultStatus} from '@/types/check/ICheckResult';

	const props = defineProps<{
		pageTitle: string,
		loading: boolean,
		checkEnum?: string,
		checkStatus?: string,
		checkSections?: Array<ICheckSectionResultStatus>
		lastUpdatedBy?: string,
		lastUpdated?: string
	}>();

	const router = useRoute();

	const showInvitationModal: Ref<boolean> = ref(false);

	const companyId = computed(() => NumberUtils.stringToNumber(String(router.params.companyId)));

	const hasCoachInvited = computed(() => store.getters.hasCoachInvited(companyId.value));

	const isCompanyOwner = computed(() => store.getters.isUserCompanyOwner(companyId.value));

	const lastUpdatedDate = computed(() => getFormattedDate(props.lastUpdated));

	const lastUpdatedBy = computed(() => props.lastUpdatedBy ?? '');

</script>
