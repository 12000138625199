<template>
	<ul v-if="htmlContent.length > 0" v-html="htmlContent">
	</ul>
	<vijfxbeter-header-menu-items-fallback v-else />
</template>

<script>

	import VijfxbeterHeaderMenuItemsFallback from '@/components/wrappers/VijfxbeterHeaderMenuItemsFallback.vue';

	const contentUrl = import.meta.env.VITE_APP_WEBSITE_DOMAIN + '/verbetercheck/header-menu-items';
	export default {
		name: 'vijfxbeter-header-menu-items',
		components: {VijfxbeterHeaderMenuItemsFallback},
		data() {
			return {
				htmlContent: '',
				contentUrl
			};
		},
		methods: {
			fetchHeaderMenuItems() {
				return fetch(contentUrl)
					.then((response) => {
						if (!response.ok) {
							throw new Error('HTTP error ' + response.status);
						}
						return response.text();
					})
					.then(text => this.htmlContent = text)
					.catch((error) => {
						console.error('There has been a problem while fetching the header menu items:', error);
						this.htmlContent = this.fallbackHtml;
						const oneMinuteRetry = 60000;
						setTimeout(() => this.fetchHeaderMenuItems(), oneMinuteRetry);
					});
			}
		},
		mounted() {
			this.fetchHeaderMenuItems();
		}
	};
</script>

