<template>
	<dialog-container :show="show"
	                  @close="closeDialog"
	                  title="Actiepunt toevoegen"
	                  width="600">
		<div class="popup-content" id="modal-add-action">
			<form-action :companyId="companyId"
			             :theme="theme"
			             :adviceId="adviceId"
			             @cancel="closeDialog"
			             @submitted="closeDialog"
			             new-action />
		</div>
	</dialog-container>
</template>

<script>
	import DialogContainer from '../../plugins/confirmDialog/DialogContainer';
	import FormAction from '@/components/actionitem/FormAction';

	export default {
		name: 'ModalAddAction',
		components: {FormAction, DialogContainer},
		computed: {
			show() {
				return this.$store.state.modals.addAction.show;
			},
			companyId() {
				return this.$store.state.modals.addAction.companyId;
			},
			theme() {
				return this.$store.state.modals.addAction.theme;
			},
			adviceId() {
				return this.$store.state.modals.addAction.adviceId;
			}
		},
		methods: {
			closeDialog() {
				this.$store.dispatch('modals/addAction/closeDialog');
			}
		}
	};
</script>

<style lang="scss" scoped>
	#modal-add-action {
		:deep(textarea) {
			width: 100%;
		}
	}
</style>
