<script>
	export default {
		name: 'BuildInfoConsole',
		mounted() {
			if (import.meta.env.VITE_APP_ADD_BUILD_INFO) {
				const buildInfo = {
					env: import.meta.env.VITE_APP_MODE,
					mode: import.meta.env.MODE,
					branche: import.meta.env.VITE_APP_BUILD_INFO_BRANCHE,
					lastCommitHash: import.meta.env.VITE_APP_BUILD_INFO_COMMIT_HASH,
					lastCommitTime: import.meta.env.VITE_APP_BUILD_INFO_COMMIT_TIME,
					buildTimestamp: import.meta.env.VITE_APP_BUILD_INFO_TIME
				};

				if (typeof console.table === 'function') {
					console.table(buildInfo);
				} else {
					console.log(buildInfo);
				}
			}
		},
		render: () => ''
	};
</script>
