<template>
	<div>
		<slot :itemsOnPage="visibleItems"></slot>
		<div v-if="showPagination" class="block-pagination-component">
			<ul>
				<li :class="{disabled: !hasPreviousPage}" class="prev">
					<button :disabled="!hasPreviousPage" @click.prevent="previous">
						<i class="icon-chevron-left"></i>Vorige
					</button>
				</li>

				<li v-for="pageButton in pageButtons"
				    :key="pageButton"
				    :class="{current : pageButton == currentPage}"
				    class="pageNumber">

					<button @click.prevent="showPage(pageButton)">
						{{ pageButton }}
					</button>
				</li>

				<li :class="{disabled: !hasNextPage}" class="next">
					<button :disabled="!hasNextPage" @click.prevent="next">
						Volgende<i class="icon-chevron-right"></i>
					</button>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PagePagination',
		props: {
			// the array of items for which pagination is required.
			items: {
				type: Array,
				required: true
			},
			// The amount of items shown on a single page
			itemsPerPage: {
				type: Number,
				default: 10
			},
			// The amount of page buttons shown before the current page
			maxVisiblePrevious: {
				type: Number,
				default: 5
			},
			// The amount of page buttons shown after the current page
			maxVisibleNext: {
				type: Number,
				default: 5
			},
			// Shows the pagination buttons when there is only a single page. If you are using a seperate filter this
			// can make the pagination appear and disappear.
			showPaginationForSinglePage: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				currentPage: 1
			};
		},
		watch: {
			items() {
				this.showPage(1);
			}
		},
		computed: {
			hasPreviousPage() {
				return this.currentPage !== 1;
			},
			numberOfPages() {
				if (!this.items.length) {
					return 0;
				}
				return Math.ceil(this.items.length / this.itemsPerPage);
			},
			hasNextPage() {
				return this.currentPage !== this.numberOfPages;
			},
			showPagination() {
				return this.items.length > this.itemsPerPage || this.showPaginationForSinglePage;
			},
			visibleItems() {
				const from = this.itemsPerPage * (this.currentPage - 1);
				const to = this.itemsPerPage * this.currentPage;
				return this.items.slice(from, to);
			},
			pageButtons() {
				const lowestPage = this.currentPage <= this.maxVisiblePrevious ? 1 : this.currentPage - this.maxVisiblePrevious;
				const highestPage = this.currentPage >= this.numberOfPages - this.maxVisibleNext ?
					this.numberOfPages : this.currentPage + this.maxVisibleNext;

				return Array.from(new Array(highestPage - lowestPage + 1), (x, i) => i + lowestPage);
			}
		},
		render() {
			return this.$slots.default({
				visibleItems: this.visibleItems
			});
		},
		methods: {
			next() {
				if (this.hasNextPage) {
					this.showPage(this.currentPage + 1);
				}
			},
			previous() {
				if (this.hasPreviousPage) {
					this.showPage(this.currentPage - 1);
				}
			},
			showPage(i) {
				if (i < 1 || i > this.numberOfPages) {
					return;
				}
				this.currentPage = i;
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.block-pagination-component {

		margin: 0 0 20px;

		&.search {
			margin-top: 20px;
		}

		ul, li {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			display: inline-block;
			color: $blue;

			&.disabled {
				opacity: 0.25;
				padding: 0 0.5em;
			}

			a {
				padding: 0.5em;
			}

		}

		li.pageNumber {

			&.current {

				a {
					font-weight: bold;
					color: #000000;
					text-decoration: none;
				}

			}
		}

		li.prev {
			padding-left: 0;

			a {
				margin-left: -0.5em;
			}

			.icon-chevron-left {
				font-size: 10px;
				margin-right: 8px;
			}

		}

		li.next {

			.icon-chevron-right {
				font-size: 10px;
				margin-left: 8px;
			}

		}
	}

	button {
		border: 0;
	}

	li.current button {
		font-weight: 700;
		color: #000000;
		text-decoration: none;
	}
</style>
