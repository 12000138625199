<template>
	<transition name="fade">
		<template v-if="showIf">
			<slot></slot>
		</template>
	</transition>
</template>
<script>
	export default {
		name: 'TransitionFade',
		props: {
			showIf: {
				type: Boolean,
				required: true
			}
		}
	};
</script>

<style lang="scss" scoped>
	.fade-enter-active, .fade-leave-active {
		transition: opacity .2s;
	}

	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>
