<template>
	<check-page-template>
		<div class="question-content">
			<h4>Bedrijfsgegevens invoeren</h4>

			<h1>Voor welk bedrijf wil je de verbetercheck {{ checkToStartTitle }} uitvoeren?</h1>

			<p>
				Je kunt de verbetercheck {{ checkToStartTitle }} starten voor een eigen bedrijf of een bedrijf
				waarvoor je bent uitgenodigd om aan mee te werken of te administreren.
			</p>

			<p v-if="loading">Bedrijven worden geladen...</p>

			<template v-else>
				<div v-if="allowedCompanies.length > 0" class="question-checkbox-list">
					<div v-for="company in allowedCompanies" :key="company.id" class="question-checkbox-list-item">
						<input :id="String(company.id)"
						       :checked="company.id === selectedCompanyId"
						       :value="company.id"
						       type="radio"
						       @change="handleChange" />

						<label :for="String(company.id)">{{ company.name }}</label>
					</div>
				</div>

				<p v-else>
					<strong>
						Je hebt geen bedrijven waarvoor je de verbetercheck {{ checkToStartTitle }} kunt starten.
					</strong>
				</p>

				<p>
					<router-link :to="{ name: pageHelper.PAGE_ADD_COMPANY}" class="create-company-link">
						<em class="icon-chevron-right"></em> Maak een nieuw bedrijf aan
					</router-link>
				</p>

				<div class="button-bar">
					<button :class="{'disabled': !selectedCompany}"
					        :disabled="!selectedCompany"
					        class="button-blue"
					        type="button"
					        @click="handleSubmit">
						Ok, verder
					</button>

					<button class="button-no-border"
					        type="button"
					        @click="router.back()">
						Vorige
					</button>
				</div>
			</template>
		</div>
	</check-page-template>
</template>

<script lang="ts" setup>
	import {computed, onMounted, ref, Ref} from 'vue';
	import CheckPageTemplate from '@/components/check/CheckPageTemplate.vue';
	import {pageHelper} from '@/utils/pageHelper';
	import {ICompanyChecks} from '@/types/ICompanyChecks';
	import {checkStoreMethod} from '@/store/check/checkStore';
	import {Api} from '@/utils/api';
	import NumberUtils from '@/utils/numberUtils';
	import {checkHelper} from '@/utils/checkHelper';
	import {useRoute, useRouter} from 'vue-router';
	import {useStore} from 'vuex';

	const route = useRoute();
	const store = useStore();
	const router = useRouter();

	const loading: Ref<boolean> = ref(true);

	const companies: Ref<Array<ICompanyChecks>> = ref([]);

	const selectedCompany: Ref<ICompanyChecks | undefined> = ref(undefined);

	const selectedCompanyId = computed(() => selectedCompany.value?.id);

	const checkToStart = computed(() => checkHelper.getChecksArray().find(check => check.enum === route.query.check));

	const checkToStartTitle = computed(() => checkToStart.value?.title);

	const allowedCompanies = computed(() => companies.value.filter(company => company.permission.hasWriteAccess)
		.filter(company => company.checks.find(check => check.check === checkToStart.value?.enum))
		.sort((a, b) => a.name.localeCompare(b.name)));

	const handleChange = (event: Event) => {
		const target = event.target as HTMLInputElement;
		if (target.checked) {
			const value = NumberUtils.stringToNumber(target.value);
			selectedCompany.value = companies.value.find(company => company.id === value);
		}
	};

	const handleSubmit = async () => {
		if (!selectedCompany.value) {
			return;
		}

		const companyCheck = selectedCompany.value.checks.find(check => check.check === checkToStart.value?.enum);

		if (!companyCheck) {
			return;
		}

		const companyId = selectedCompany.value.id;

		if (companyCheck.started) {
			await router.push(companyCheck.checkUrl);
		} else {
			await checkHelper.startCheck(companyId, companyCheck.check)
				.then(() => router.push(companyCheck.checkUrl))
				.catch(() => store.dispatch(checkStoreMethod.SET_ERROR, 'De verbetercheck kan niet gestart worden.'));
		}
	};

	const loadCompanies = async () => {
		loading.value = true;

		await Api.getCompanies()
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then(async (response: any) => {
				companies.value = response.responseObject;
				await store.dispatch('updateCompanyList', response.responseObject);
			})
			.catch(() => store.dispatch(checkStoreMethod.SET_ERROR, 'Bedrijven kunnen niet worden opgehaald.'))
			.finally(() => loading.value = false);
	};

	onMounted(async () => {
		if (store.getters.userHasRoleCoach()) {
			await router.replace({name: pageHelper.PAGE_MY_COMPANIES});
		} else {
			await loadCompanies();
		}
	});
</script>

<style lang="scss" scoped>
	@import '@/assets/css/variables';
	@import '@/assets/css/global/icons';

	.question-content {
		padding-top: 62px;

		h1 {
			@media screen and (max-width: 480px) {
				font-size: 20px;
				word-break: break-word;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
				hyphens: auto;
			}
		}

		h4 {
			color: $red;
			font-weight: 400;
			margin: .4em 0 1em;
		}
	}

	.question-checkbox-list {
		margin: 0 0 20px;

		.question-checkbox-list-item {
			input {
				display: none;
			}

			label {
				display: block;
				padding: 6px 10px;
				background: rgba(255, 255, 255, 0.5);
				margin: 0 0 1px;
				position: relative;
				font-weight: normal;
				line-height: 2em;

				&:focus,
				&:hover {
					background: rgba(255, 255, 255, 0.6);
				}

				&:before {
					@extend .base-icon-style;

					content: '';
					width: 20px;
					height: 20px;
					line-height: 21px !important;
					text-align: center;
					display: block;
					background: #ffffff;
					float: right;
					margin: 4px 0 4px 10px;
					cursor: pointer;
				}
			}

			input:checked ~ label {
				background: #ffffff;
				-webkit-transition: background 0.2s, border-color 0.2s, color 0.3s;
				transition: background 0.2s, border-color 0.2s, color 0.3s;

				&:before {
					@extend .icon-check;

					-webkit-transition: background 0.2s;
					transition: background 0.2s;
					background: $blue;
					color: #ffffff;
					font-size: 9px;
					line-height: 21px;
				}
			}
		}
	}

	.create-company-link {
		vertical-align: middle;
	}
</style>
