<template>

	<vuelidate-form ref="formLogin"
	                :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                :warning-message="warningMessage"
	                class="content-form"
	                submit-button-text="Aanmelden"
	                @validSubmit="handleSubmit">

		<form-input v-model.trim="v$.form.email.$model"
		            :validation="v$.form.email"
		            autocomplete="email"
		            label="E-mailadres"
		            name="email"
		            required
		            type="email" />

		<div class="row">
			<div class="col-6 col-sm-12">

				<form-input v-model.trim="v$.form.givenname.$model"
				            :validation="v$.form.givenname"
				            autocomplete="given-name"
				            label="Voornaam"
				            name="givenname"
				            required
				            type="text" />

				<form-input v-model.trim="form.surnamePrefix"
				            label="Tussenvoegsel"
				            name="surnamePrefix"
				            type="text" />

				<form-input v-model.trim="v$.form.surname.$model"
				            :validation="v$.form.surname"
				            autocomplete="family-name"
				            label="Achternaam"
				            name="surname"
				            required
				            type="text" />
			</div>

			<div class="col-6 col-sm-12">
				<form-input v-model.trim="v$.form.password.$model"
				            :validation="v$.form.password"
				            autocomplete="new-password"
				            label="Nieuw wachtwoord"
				            maxlength="255"
				            minlength="8"
				            name="password"
				            placeholder="Nieuw wachtwoord"
				            required
				            type="password" />

				<form-input v-model.trim="v$.form.confirmpassword.$model"
				            :validation="v$.form.confirmpassword"
				            autocomplete="new-password"
				            label="Herhaal nieuw wachtwoord"
				            maxlength="255"
				            minlength="8"
				            name="confirmpassword"
				            placeholder="Nieuw wachtwoord"
				            required
				            type="password" />

				<p class="form-explanation">
					<strong>Let op:</strong>
					je wachtwoord moet bestaan uit minimaal acht karakters,
					waarvan minimaal één kleine letter, één hoofdletter en één cijfer.
				</p>

			</div>
		</div>

		<div class="row">
			<div class="col-12 col-sm-12">
				<label>Voorwaarden</label>

				<form-checkbox-block id="userConditions"
				                     v-model="form.userConditions"
				                     :validation="v$.form.userConditions"
				                     name="userConditions"
				                     required>
					<template v-slot:hiddenField>
						<input v-model="form._userConditions" name="_userConditions" type="hidden">
					</template>
					Ik ga akkoord met de
					<a :href="linkToUserConditionsPage" target="_blank">gebruikersvoorwaarden</a>*
				</form-checkbox-block>

				<form-checkbox-block id="termsOfUse"
				                     v-model="form.termsOfUse"
				                     :validation="v$.form.termsOfUse"
				                     name="termsOfUse"
				                     required>
					<template v-slot:hiddenField>
						<input v-model="form._termsOfUse" name="_termsOfUse" type="hidden">
					</template>

					Ik ga akkoord met het
					<a :href="linkToPrivacyPage" target="_blank">privacy statement</a>*

				</form-checkbox-block>

				<form-checkbox-block id="subscribeForNewsletter"
				                     v-model="form.subscribeForNewsletter"
				                     false-value=""
				                     name="subscribeForNewsletter"
				                     required
				                     true-value="true">
					<template v-slot:hiddenField>
						<input name="_subscribeForNewsletter" type="hidden" value="on">
					</template>

					Ik meld mij aan voor de nieuwsbrief
				</form-checkbox-block>
			</div>
		</div>

	</vuelidate-form>

</template>

<script>
	import {email, maxLength, minLength, required, sameAs} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import FormInput from '../forms/FormInput';
	import VuelidateForm from '../forms/VuelidateForm';
	import {mustBeTrue, password} from '@/utils/customValidators';
	import FormCheckboxBlock from '@/components/forms/FormCheckboxBlock';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormRegister',
		components: {FormCheckboxBlock, VuelidateForm, FormInput},
		props: {
			formMode: {
				type: String,
				default: 'header'
			},
			showRegisterLink: {
				type: Boolean,
				default: false
			},
			registerHref: {
				type: String
			},
			redirect: {
				type: [String, Object]
			}
		},
		data() {
			return {
				isSubmitting: false,
				showSticky: false,
				warningMessage: '',
				linkToUserConditionsPage: '/site/nl/gebruikersovereenkomst',
				linkToPrivacyPage: '/site/nl/privacy-statement',
				form: {
					bookmark: '',
					email: '',
					givenname: '',
					surnamePrefix: '',
					surname: '',
					password: '',
					confirmpassword: '',
					userConditions: '',
					termsOfUse: '',
					subscribeForNewsletter: '',
					registrationKey: '',
					invitationKey: '',
					_userConditions: 'on',
					_termsOfUse: 'on',
					_subscribeForNewsletter: 'on'
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations() {
			return {
				form: {
					email: {
						required,
						email
					},
					givenname: {
						required
					},
					surname: {
						required
					},
					password: {
						required,
						minLength: minLength(8),
						maxLength: maxLength(255),
						password
					},
					confirmpassword: {
						sameAsPassword: sameAs(this.form.password)
					},
					userConditions: {
						required,
						mustBeTrue
					},
					termsOfUse: {
						required,
						mustBeTrue
					}
				}
			};
		},
		computed: {},
		methods: {
			getFormDataOfForm(formElement) {
				return new FormData(formElement);
			},
			async handleSubmit(event) {
				this.isSubmitting = true;
				this.warningMessage = '';

				// posted formData is retrieved from the html form element instead of the this.form object
				const formData = this.getFormDataOfForm(event.target);

				//add hidden properties to form
				formData.append('registrationKey', this.form.registrationKey);
				formData.append('invitationKey', this.form.invitationKey);

				await Api.postRegister(formData)
					.then((result) => {
						if (result.globalerrors?.length > 0) {
							throw new Error(result.globalerrors[0]);
						}
						this.$emit('success', this.form.email);
					})
					.catch((result) => {
						if (result.globalerrors?.length > 0) {
							this.warningMessage = result.globalerrors[0];
						}
						if (result.message?.length > 0) {
							this.warningMessage = result.message;
							return;
						}
						this.warningMessage = 'Registreren is niet gelukt';
					})
					.finally(() => {
						this.isSubmitting = false;
					});
			},
			fillForm() {
				const email = this.$route.query.email?.trim();
				const invitationType = this.$route.query.push;

				if (email && invitationType === 'registeranonymous') {
					this.form.registrationKey = this.$route.query.registration;
				}

				if (email && invitationType === 'registeroninvite') {
					this.form.invitationKey = this.$route.query.registration;
				}

				if (email) {
					this.form.email = decodeURIComponent(email);
				}
			}
		},
		mounted() {
			this.fillForm();
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/css/variables';
</style>
