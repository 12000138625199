import {FetchHelper} from './fetchHelper';

export const MIJN_ENDPOINT = import.meta.env.VITE_APP_MIJN5XBETER_BACKEND;
export const RM_BASE = `${MIJN_ENDPOINT}/rest`;
export const RM_COMPANY = `${RM_BASE}/companies`;
const RM_EXTERNAL_COMPANY = `${RM_BASE}/companies/externalid`;
const RM_ORDERS = `${RM_BASE}/orders`;
const RM_PASSWORD = `${RM_BASE}/password`;

export class Api {
	static async get(url) {
		return FetchHelper.loadJson(url);
	}

	static async post(url, body) {
		if (body instanceof FormData) {
			return FetchHelper.executeFetch(url, 'POST', null, body);
		}

		return FetchHelper.executeFetchJson(url, 'POST', body);
	}

	static async put(url, body) {
		if (body instanceof FormData) {
			return FetchHelper.executeFetch(url, 'PUT', null, body);
		}

		return FetchHelper.executeFetchJson(url, 'PUT', body);
	}

	static async delete(url) {
		return FetchHelper.executeFetchJson(url, 'DELETE');
	}

	static postLogin(body) {
		return this.post(`${MIJN_ENDPOINT}/login`, body);
	}

	static getLogin() {
		return this.get(`${MIJN_ENDPOINT}/login`);
	}

	static getLogout() {
		return this.get(`${MIJN_ENDPOINT}/logout`);
	}

	static getCompanyId(id) {
		return this.get(`${RM_COMPANY}/externalId/${id}`);
	}

	static getCompanyAdvices(id) {
		return this.get(`${RM_COMPANY}/${id}/advices`);
	}

	static getCompanyActions(id) {
		return this.get(`${RM_COMPANY}/${id}/actionitems`);
	}

	static getAdvice(companyId, adviceId) {
		return this.get(`${RM_COMPANY}/${companyId}/advices/${adviceId}`);
	}

	static getAction(companyId, actionId) {
		return this.get(`${RM_COMPANY}/${companyId}/actionitems/${actionId}`);
	}

	static getCompanyVisitReports(companyId) {
		return this.get(`${RM_COMPANY}/${companyId}/visitreports`);
	}

	static getVisitReport(companyId, visitId) {
		return this.get(`${RM_COMPANY}/${companyId}/visitreports/${visitId}`);
	}

	static postAddAdvice(companyId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/advices`, body);
	}

	static postAddAction(companyId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/actionitems`, body);
	}

	static postUpdateAdvice(companyId, adviceId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/advices/${adviceId}`, body);
	}

	static postUpdateAction(companyId, actionId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/actionitems/${actionId}`, body);
	}

	static deleteVisitReport(companyId, reportId) {
		return this.delete(`${RM_COMPANY}/${companyId}/visitreports/${reportId}`);
	}

	static postAddVisitReport(companyId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/visitreports`, body);
	}

	static postUpdateVisitReport(companyId, reportId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/visitreports/${reportId}`, body);
	}

	static postUpdateVisitReportArchivedStatus(companyId, reportId) {
		return this.post(`${RM_COMPANY}/${companyId}/visitreports/${reportId}/archive`);
	}

	static postUpdateAdviceArchivedStatus(companyId, adviceId) {
		return this.post(`${RM_COMPANY}/${companyId}/advices/${adviceId}/archive`);
	}

	static postAddOrUpdateVisitReportRating(companyId, reportId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/visitreports/${reportId}/rating`, body);
	}

	static getCompanyChecksOverview(id) {
		return this.get(`${RM_COMPANY}/${id}/checks`);
	}

	/**
	 * The value of check should be a value from the java enum Check (see checkHelper.isValidCheck(check);
	 */
	static deleteCheckForCompany(companyId, check) {
		return this.delete(`${RM_COMPANY}/${companyId}/delete-check/${check}`);
	}

	static postCreateCompany(body) {
		return this.post(`${RM_COMPANY}`, body);
	}

	static getCompanies() {
		return this.get(`${RM_COMPANY}`);
	}

	static getIsPasswordResetTokenValid(token) {
		return this.get(`${RM_PASSWORD}/reset-password-possible?token=${token}`);
	}

	static postUpdateCompany(id, body) {
		return this.post(`${RM_COMPANY}/${id}/update`, body);
	}

	static postChangeCompanyOwner(id, body) {
		return this.post(`${RM_BASE}/admin/companies/${id}/owner`, body);
	}

	static getCompanyDetail(id) {
		return this.get(`${RM_COMPANY}/${id}/update`);
	}

	static getCompanyDetailForAdmin(id) {
		return this.get(`${RM_BASE}/admin/companies/${id}`);
	}

	static deleteCompany(id) {
		return this.delete(`${RM_COMPANY}/${id}/delete`);
	}

	static postUpdatePassword(body) {
		return this.post(`${RM_BASE}/password/update`, body);
	}

	static postResetPassword(body) {
		return this.post(`${RM_PASSWORD}/reset-password`, body);
	}

	static postForgotPassword(body) {
		return this.post(`${RM_PASSWORD}/forgot-password`, body);
	}

	static getUserDetail() {
		return this.get(`${RM_BASE}/person/detail`);
	}

	static getUserDetailForAdmin(id) {
		return this.get(`${RM_BASE}/admin/users/${id}`);
	}

	static postUpdateUser(body) {
		return this.post(`${RM_BASE}/person/detail`, body);
	}

	static postUpdateUserByAdmin(id, body) {
		return this.post(`${RM_BASE}/admin/users/${id}`, body);
	}

	static postInvite(body) {
		return this.post(`${RM_BASE}/invitation`, body);
	}

	static inviteColleagueOrExternalAdvisor(body, companyId, externalCompanyId = false) {
		if (externalCompanyId) {
			return this.post(`${RM_EXTERNAL_COMPANY}/${companyId}/personinvitation`, body);
		}
		return this.post(`${RM_COMPANY}/${companyId}/personinvitation`, body);
	}

	static inviteCoach(body, companyId, externalCompanyId = false) {
		if (externalCompanyId) {
			return this.post(`${RM_EXTERNAL_COMPANY}/${companyId}/coachinvitation`, body);
		}
		return this.post(`${RM_COMPANY}/${companyId}/coachinvitation`, body);
	}

	static getColleagueOrExternalAdvisorInvite(companyId, inviteId) {
		return this.get(`${RM_COMPANY}/${companyId}/personinvitation/${inviteId}`);
	}

	static deleteColleagueOrExternalAdvisorInvite(companyId, inviteId) {
		return this.delete(`${RM_COMPANY}/${companyId}/personinvitation/${inviteId}`);
	}

	static deleteCoachInvite(companyId) {
		return this.delete(`${RM_COMPANY}/${companyId}/coachinvitation`);
	}

	static updateColleagueOrExternalAdvisorInvite(companyId, inviteId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/personinvitation/${inviteId}`, body);
	}

	static getAllUsers() {
		return this.get(`${RM_BASE}/admin/users`);
	}

	static getAllCompanies() {
		return this.get(`${RM_BASE}/admin/companies`);
	}

	static postMigrateImprovementCheck(body) {
		return this.post(`${RM_BASE}/admin/migrate-improvement-check`, body);
	}

	static getCompanyRecords(companyId) {
		return this.get(`${RM_COMPANY}/${companyId}/company-records`);
	}

	static getCompanyRecord(companyId, companyRecordId) {
		return this.get(`${RM_COMPANY}/${companyId}/company-records/${companyRecordId}`);
	}

	static postAddCompanyRecord(companyId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/company-records`, body);
	}

	static postUpdateCompanyRecord(companyId, companyRecordId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/company-records/${companyRecordId}`, body);
	}

	static deleteCompanyRecord(companyId, companyRecordId) {
		return this.delete(`${RM_COMPANY}/${companyId}/company-records/${companyRecordId}`);
	}

	static postUploadCompanyRecordFile(companyId, companyRecordId, body) {
		return this.post(`${RM_COMPANY}/${companyId}/company-records/${companyRecordId}/files`, body);
	}

	static deleteCompanyRecordFile(companyId, companyRecordId, fileId) {
		return this.delete(`${RM_COMPANY}/${companyId}/company-records/${companyRecordId}/files/${fileId}`);
	}

	static postOrder(body) {
		return this.post(`${RM_ORDERS}`, body);
	}

	static getCompanyDataForOrder() {
		return this.get(`${RM_COMPANY}/own`);
	}

	static getOrderHistory() {
		return this.get(`${RM_ORDERS}`);
	}

	static postRegister(body) {
		return this.post(`${RM_BASE}/registration`, body);
	}
}

export const prefixWithBackendDomain = url => MIJN_ENDPOINT + url;
