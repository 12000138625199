<template>
	<div class="block-search-field">
		<form @submit.prevent="">
			<input :aria-label="label"
			       :placeholder="label"
			       :value="modelValue"
			       @input="$emit('update:modelValue', $event.target.value)"
			       class="block-search-field_input"
			       enterkeyhint="search"
			       type="text"
			       ref="search"/>
			<button class="block-search-field_submit" type="submit">
				<i class="icon-search"></i>
			</button>

			<button @click.prevent="clearValue"
			        class="block-search-field_reset button button-no-border"
			        type="button">
				Wis zoekopdracht
			</button>
		</form>
	</div>
</template>

<script>
	export default {
		name: 'BlockSearchField',
		props: {
			modelValue: {
				type: String,
				default: ''
			},
			label: {
				type: String,
				default: 'Zoeken'
			}
		},
		methods: {
			clearValue() {
				this.$emit('update:modelValue', '');
				this.$refs.search.focus();
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.block-search-field {
		position: relative;
		margin: 0 0 20px;
	}

	input.block-search-field_input {
		display: block;
		width: 100%;
		border-color: $grey;
		padding-left: 10px;
		padding-right: 40px;

		&:focus {
			border-color: #000;
		}
	}

	.block-search-field_submit {
		position: absolute;
		top: 0;
		right: 0;
		background: none;
		border: none;
		color: $blue;
		line-height: 1em;
		font-size: 15px;
		padding: calc((40px - 18px) / 2);

		.block-page-header ~ .block-search-field & {
			.icon-search {
				font-size: 18px;
			}
		}
	}

	.block-search-field_reset {
		display: inline-block;
		padding: 0 10px;

		&.disabled {
			opacity: 0.25;
			text-decoration: none !important;
			cursor: default;
		}
	}
</style>
