/* eslint-disable */
import {initSentry} from '../sentry-config';
import Dialog from '@/plugins/confirmDialog/confirmDialog';
import router from '@/router';

// Define the wrapper components
import {createApp} from 'vue'
import VijfxbeterMijn from '@/components/wrappers/VijfxbeterMijn.vue';
import VijfxbeterAccount from '@/components/decorations/VijfxbeterAccount.vue';
import LinkMijn5xbeter from '@/components/LinkMijn5xbeter.vue';
import VijfxbeterHeader from '@/components/wrappers/VijfxbeterHeader.vue';
import VijfxbeterFooter from '@/components/wrappers/VijfxbeterFooter.vue';
import FormLogin from '@/components/login/FormLogin.vue';
import ProductWrapper from '@/components/orders/ProductWrapper.vue';
import LinkShoppingcartHeader from '@/components/orders/LinkShoppingcartHeader.vue';
import ModalInvitationWrapper from '@/components/company/ModalInvitationWrapper.vue';
import WebsiteCheckCompanyOverview from '@/components/website/WebsiteCheckCompanyOverview.vue';
import store from '@/store';

const app = createApp({});

initSentry(app, router)

app.use(Dialog);
app.use(router);
app.use(store);

app.component('vijfxbeter-mijn', VijfxbeterMijn);
app.component('vijfxbeter-account', VijfxbeterAccount);
app.component('link-mijn5xbeter', LinkMijn5xbeter);
app.component('vijfxbeter-header', VijfxbeterHeader);
app.component('vijfxbeter-footer', VijfxbeterFooter);
app.component('form-login', FormLogin);
app.component('product-wrapper', ProductWrapper);
app.component('link-shoppingcart-header', LinkShoppingcartHeader);
app.component('modal-invitation-wrapper', ModalInvitationWrapper);
app.component('website-check-company-overview', WebsiteCheckCompanyOverview);

app.mount('#my5xbeterapp');
