<template>
	<footer style="background: rgb(255, 255, 255);">
		<div class="row footer-contact show-on-small">
			<p class="h2">Contact? Bel de Verbeterlijn: <strong>0800 555 50 05</strong> (gratis)</p>
		</div>
		<div class="row footer-logos">
			<div class="column"><h3>5xbeter is een project van:</h3></div>
			<div class="column"><a href="http://www.metaalunie.nl" target="_blank"><img alt="Koninklijke metaalunie"
			                                                                            data-src-color="https://www.5xbeter.nl/template/assets/img/footer-logo-metaalunie.png"
			                                                                            data-src-grey="https://www.5xbeter.nl/template/assets/img/footer-logo-metaalunie.png"
			                                                                            src="https://www.5xbeter.nl/template/assets/img/footer-logo-metaalunie.png"></a>
			</div>
			<div class="column"><a href="http://www.fme.nl/" target="_blank"><img alt="FME"
			                                                                      data-src-color="https://www.5xbeter.nl/template/assets/img/footer-logo-fme.png"
			                                                                      data-src-grey="https://www.5xbeter.nl/template/assets/img/footer-logo-fme.png"
			                                                                      src="https://www.5xbeter.nl/template/assets/img/footer-logo-fme.png"></a>
			</div>
			<div class="column"><a href="http://www.fnvmetaal.nl/" target="_blank"><img alt="FNV Bondgenoten"
			                                                                            data-src-color="https://www.5xbeter.nl/template/assets/img/footer-logo-fnv.png"
			                                                                            data-src-grey="https://www.5xbeter.nl/template/assets/img/footer-logo-fnv.png"
			                                                                            src="https://www.5xbeter.nl/template/assets/img/footer-logo-fnv.png"></a>
			</div>
			<div class="column"><a href="http://www.cnvvakmensen.nl/" target="_blank"><img alt="CNV Vakmensen"
			                                                                               data-src-color="https://www.5xbeter.nl/template/assets/img/footer-logo-cnv.png"
			                                                                               data-src-grey="https://www.5xbeter.nl/template/assets/img/footer-logo-cnv.png"
			                                                                               src="https://www.5xbeter.nl/template/assets/img/footer-logo-cnv.png"></a>
			</div>
			<div class="column"><a href="http://www.unie.nl/" target="_blank"><img alt="De Unie"
			                                                                       data-src-color="https://www.5xbeter.nl/template/assets/img/footer-logo-unie.png"
			                                                                       data-src-grey="https://www.5xbeter.nl/template/assets/img/footer-logo-unie.png"
			                                                                       src="https://www.5xbeter.nl/template/assets/img/footer-logo-unie.png"></a>
			</div>
		</div>
		<div class="row footer-contact hide-for-small">
			<p class="h2">Contact? Bel de Verbeterlijn: <strong>0800 555 50 05</strong> (gratis)</p>
		</div>
		<div class="row footer-menu">
			<div class="col-3 hide-for-small">
				<ul>
					<li class=""><h3>Thema's</h3>
						<ul>
							<li class=""><a href="/site/nl/themas/fysieke-belasting">Fysieke belasting</a></li>
							<li class=""><a href="/site/nl/themas/gevaarlijke-stoffen">Gevaarlijke stoffen</a></li>
							<li class=""><a href="/site/nl/themas/lasrook">Lasrook</a></li>
							<li class=""><a href="/site/nl/themas/machineveiligheid">Machineveiligheid</a></li>
							<li class=""><a href="/site/nl/themas/schadelijk-geluid">Schadelijk geluid</a></li>
							<li class=""><a href="https://www.5xbeter.nl/site/nl/themas/valgevaar">Tijdelijk werken op
							                                                                       hoogte</a></li>
							<li class=""><a href="/site/nl/themas/overige-themas">Overige thema's</a></li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="col-3 hide-for-small">
				<ul>
					<li class=""><h3>5xbeter</h3>
						<ul>
							<li class=""><a href="/site/nl/nieuws">Nieuws</a></li>
							<li class=""><a href="/site/nl/over-5xbeter/agenda">Agenda</a></li>
							<li class=""><a href="/site/nl/over-5xbeter">Over 5xbeter</a></li>
							<li class=""><a href="/site/nl/over-5xbeter/contact">Contact</a></li>
							<li class=""><a href="/site/nl/faq">FAQ</a></li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="col-3 hide-for-small">
				<ul>
					<li class=""><h3>Persoonlijk</h3>
						<ul>
							<li class=""><a href="/mijn5xbeter/company/overview.web?action=showCompany">Mijn 5xbeter</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="col-3 hide-for-small">
				<ul>
					<li class=""><h3>Social media</h3>
						<ul>
							<li class=""><a href="https://www.linkedin.com/company/5xbeter/">LinkedIn</a></li>
							<li class=""><a href="http://www.youtube.com/user/5xbeter">YouTube</a></li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
		<div class="row footer-social show-for-mobile-only"><h3>Je vindt 5xbeter ook op:</h3>
			<p><a href="https://www.linkedin.com/company/5xbeter"
			      rel="noopener noreferrer"
			      target="_blank"><em class="icon-linkedin"></em></a><a href="https://www.youtube.com/user/5xbeter"
			                                                            rel="noopener noreferrer"
			                                                            target="_blank"><em class="icon-youtube"></em></a>
			</p>
		</div>
		<div class="row footer-copyright">
			<p> © <strong>2023, 5xbeter</strong> - <a href="/site/nl/cookies-disclaimer">Cookies &amp;Disclaimer</a> -<a
					href="/site/nl/gebruikersovereenkomst">Gebruikersovereenkomst</a>
			    -<a href="https://www.5xbeter.nl/site/nl/privacy-statement">Privacy Statement</a>
			    -<a href="/site/nl/over-5xbeter/contact">Suggesties?</a></p>
		</div>
	</footer>
</template>

<script>
	/**
	 * Component with static fallback HTML code for vijfxbeter-footer component.
	 */
	export default {
		name: 'vijfxbeter-footer-fallback'
	};
</script>

<style lang="scss">
	@import "@/assets/css/_variables.scss";
	@import "@/assets/css/global/_icons-mijn5xbeter.scss";
	@import "@/assets/css/website/_footer.scss";
</style>


