<template>
	<div class="block-company-overview-check"
	     style="max-height: none;">

		<check-status :check="check" class="block-company-overview-check_status" />

		<div class="block-company-overview-check_title">
			<h2>{{ checkType }}</h2>
			<h2 class="inline-header"><strong>{{ checkTitle }}</strong></h2>

			<a v-if="showUnfinishedCheckText" :href="check.checkUrl" class="hide-for-mobile">
				{{ check.numUnfinished }} onafgerond
			</a>
		</div>

		<button v-if="isRelative()"
		        class="button block-company-overview-check_button"
		        type="button"
		        v-bind:class="{'button-disabled': !canStartCheck()}"
		        @click="startCheck()">
			{{ buttonText }}
		</button>

		<!--Use a href to go to old absolute url from ImprovementCheck Application-->
		<a v-else :href="check.checkUrl"
		   class="button block-company-overview-check_button"
		   v-bind:class="{'button-disabled': !canStartCheck()}">
			{{ buttonText }}
		</a>

		<lasrook-updated-disclaimer :archived-url="getWeldingSmokeArchivedUrl()" />

		<work-at-height-disclaimer v-if="isTempWorkAtHeight" />

	</div>

</template>
<script>
	import CheckStatus from './CheckStatus';
	import {checkHelper, checkType} from '@/utils/checkHelper';
	import WorkAtHeightDisclaimer from '@/components/company/WorkAtHeightDisclaimer';
	import LasrookUpdatedDisclaimer from '@/components/company/LasrookDisclaimer';
	import {pageHelper} from '@/utils/pageHelper';

	const buttonText = [];
	buttonText[checkHelper.HAZARDOUS_SUBSTANCES.enum] = 'Bekijk register';
	buttonText[checkHelper.MACHINE_SAFETY.enum] = 'Resultaten per machine';
	buttonText[checkHelper.NOISE_HAZARD.enum] = 'Resultaten verbetercheck';
	buttonText[checkHelper.PHYSICAL_STRAIN.enum] = 'Resultaten per functiegroep';
	buttonText[checkHelper.TEMP_WORK_AT_HEIGHT.enum] = 'Resultaten';
	buttonText[checkHelper.WELDING_SMOKE.enum] = 'Resultaten per ruimte';

	export default {
		name: 'CompanyCheckListItem',
		components: {LasrookUpdatedDisclaimer, WorkAtHeightDisclaimer, CheckStatus},
		props: {
			check: {
				type: Object,
				required: true
			},
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			},
			hasWriteAccess: {
				type: Boolean,
				required: true
			}
		},
		computed: {
			pageHelper() {
				return pageHelper;
			},
			isWeldingSmoke() {
				return checkHelper.isWeldingSmoke(this.check.check);
			},
			isTempWorkAtHeight() {
				return checkHelper.isTempWorkAtHeight(this.check.check);
			},
			isPhysicalStrain() {
				return checkHelper.isPhysicalStrain(this.check.check);
			},
			checkType() {
				if (this.check.checkType === checkType.REGISTER) {
					return 'Register';
				}

				return 'Verbetercheck';
			},
			checkTitle() {
				return checkHelper.getTitle(this.check.check);
			},
			buttonText() {
				if (!this.check.started) {
					return this.check.checkType === checkType.REGISTER ? 'Start register' : 'Start Verbetercheck';
				}

				if (buttonText[this.check.check]) {
					return buttonText[this.check.check];
				}

				return 'Bekijk';
			},
			showUnfinishedCheckText() {
				return this.check.checkType !== checkType.REGISTER && this.check.numUnfinished > 0;
			},
			isUserCompanyOwner() {
				return this.$store.getters.isUserCompanyOwner(this.companyId);
			}
		},
		methods: {
			getWeldingSmokeArchivedUrl() {
				if (checkHelper.isWeldingSmoke(this.check.check)) {
					return this.check.checkArchivedUrl;
				}
			},
			canStartCheck() {
				return this.hasWriteAccess || this.check.started;
			},
			isRelative() {
				return this.check.checkEnvironment === 'MIJN_OMGEVING';
			},
			async startCheck() {
				if (!this.canStartCheck()) {
					return;
				}

				if (this.check.started && this.isPhysicalStrain) {
					await this.$router.push({
						name: pageHelper.PAGE_PHYSICAL_RESULTS,
						params: {
							companyId: this.companyId
						}
					});
					return;
				}

				if (this.check.started) {
					await this.$router.push(this.check.checkUrl);
				} else {
					await checkHelper.startCheck(this.companyId, this.check.check)
						.then(() => this.$router.push(this.check.checkUrl))
						.catch(() => this.$emit('showWarningMessage', 'De verbetercheck kan niet gestart worden'));
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.block-company-overview-check_status {
		float: left;
		width: 70px;
		--status-size: 40px;
		--status-font-size: 17px;
	}

	.block-company-overview-check_title {
		float: left;

		h2 {
			margin: 0;

			line-height: 16px;

			font-size: 16px;
		}

		.inline-header {
			display: inline-block;
			margin-right: 0.5em;

			strong {

				font-size: 20px;
				line-height: 16px;
			}
		}
	}

	.block-company-overview-check_button {
		float: right;
		min-width: 220px;

		@media #{$mobile}{
			float: none;
			width: 100%;
			margin: 10px 0 0;
		}
	}

	.button-disabled {
		pointer-events: none;
		opacity: 0.65;
		color: grey;
		border: 2px solid grey;
	}
</style>
