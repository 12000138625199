<template>
	<div v-if="!gevaarlijkeStoffen"
	     class="white-bg-container"
	     style="min-height: 440px;">

		<section v-if="!loggedIn" class="home-new-intro">
			<h2>Nieuw hier?</h2>
			<p>Meld je aan voor Mijn 5xbeter en profiteer kosteloos van:</p>
			<ul>
				<li><i class="icon-check"></i> Advies van Verbetercoaches</li>
				<li><i class="icon-check"></i> Hulpmaterialen per thema</li>
				<li><i class="icon-check"></i> Ondersteuning bij de Verbeterchecks</li>
				<li><i class="icon-check"></i> Een centraal archief voor je Verbeterchecks</li>
			</ul>

			<link-mijn5xbeter class="button" href="/aanmelden">
				Meld je nu aan
			</link-mijn5xbeter>
		</section>

		<section v-if="loggedIn" class="theme-check-overview">
			<h2>Mijn bedrijven</h2>

			<ul v-if="!userIsCoach" class="theme-check-company-list">

				<li v-if="loading">
					<div class="theme-check-company-list__item">
						<span style="opacity: .5">Bedrijven worden opgehaald...</span>
					</div>
				</li>

				<li v-for="company in companies"
				    :key="company.id">
					<link-mijn5xbeter class="theme-check-company-list__item"
					                  href="/bedrijven">
						{{ company.name }}
					</link-mijn5xbeter>
				</li>

			</ul>

			<link-mijn5xbeter class="button" href="/bedrijven">
				Bedrijvenoverzicht
			</link-mijn5xbeter>
		</section>
	</div>

	<div v-else class="button-bar">
		<a v-if="!loggedIn" :href="startNewRegisterUrl"
		   class="button button--primary button--dia">
			Start de Verbetercheck Gevaarlijke stoffen
		</a>

		<link-mijn5xbeter v-if="loggedIn" class="button button--primary button--dia" href="/bedrijven">
			Ga naar bedrijvenoverzicht
		</link-mijn5xbeter>
	</div>

</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';
	import LinkMijn5xbeter from '@/components/LinkMijn5xbeter';
	import {Api} from '@/utils/api';

	export default {
		name: 'WebsiteCheckCompanyOverview',
		components: {LinkMijn5xbeter},
		props: {
			gevaarlijkeStoffen: {
				type: Boolean,
				default: false
			},
			startNewRegisterUrl: {
				type: String
			}
		},
		computed: {
			loggedIn() {
				return this.$store.state.user.isLoggedIn;
			},
			userIsCoach() {
				return this.$store.getters.userHasRoleCoach();
			},
			pageHelper() {
				return pageHelper;
			}
		},

		data: () => ({
			loading: false,
			companies: []
		}),

		methods: {
			async loadCompanies() {
				this.loading = true;
				this.warningMessage = '';
				await Api.getCompanies()
					.then((response) => {
						this.companies = response.responseObject;
					})
					.catch(() => {
						/* Do nothing */
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},

		mounted() {
			if (this.loggedIn && !this.userIsCoach) {
				this.loadCompanies();
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.theme-check-overview {
		margin: 0 -10px;
		overflow: hidden;
	}

	.theme-check-company-list {
		list-style: none;
		padding: 0;
		margin: 0 0 2em;
		max-height: 320px;
		overflow: auto;

		& &__item {
			background: #eeeeee;
			color: #000000;
			margin: 2px 0;
			border-left: 2px solid #cccccc;
			padding: 10px;
			position: relative;
			line-height: 20px;
			font-weight: bold;
			display: block;
		}
	}
</style>
