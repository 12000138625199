<template>
	<div class="block-company">
		<div class="block-company_title" style="display:contents">
			<h2><strong v-text="company.name"></strong></h2>
		</div>

		<div v-for="check in company.checks"
		     :key="check.check">

			<check-status :check="check"
			              :color="check.checkStatus"
			              class="block-company_check-status" />
		</div>

		<router-link :to="checkOverviewLinkObject"
		             class="button block-company_button">
			Bekijk Verbeterchecks
		</router-link>

		<lasrook-updated-disclaimer :archived-url="getWeldingSmokeArchivedUrl()" />

		<work-at-height-disclaimer />

		<div v-if="!isUserCompanyOwner"
		     class="block-company_invitee">
			<em>Je kijkt mee bij dit bedrijf.</em>
		</div>
	</div>
</template>

<script>
	import CheckStatus from './CheckStatus';
	import {pageHelper} from '@/utils/pageHelper';
	import {checkHelper} from '@/utils/checkHelper';
	import WorkAtHeightDisclaimer from '@/components/company/WorkAtHeightDisclaimer';
	import LasrookUpdatedDisclaimer from '@/components/company/LasrookDisclaimer';

	export default {
		name: 'CompanyListItem',
		components: {LasrookUpdatedDisclaimer, WorkAtHeightDisclaimer, CheckStatus},
		props: {
			company: {
				type: Object,
				required: true
			}
		},
		computed: {
			checkOverviewLinkObject() {
				return {
					name: pageHelper.PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW,
					params: {
						companyId: this.company.id
					}
				};
			},
			isUserCompanyOwner() {
				return this.$store.getters.isUserCompanyOwner(this.company.id);
			}
		},
		methods: {
			getWeldingSmokeArchivedUrl() {
				const weldingSmokeCheck = this.company.checks.find(check => checkHelper.isWeldingSmoke(check.check));

				if (weldingSmokeCheck) {
					return weldingSmokeCheck.checkArchivedUrl;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/css/mixins';
	@import '@/assets/css/variables';

	.block-company {
		background: #ffffff;
		padding: 15px 20px;
		margin: 2px 0;
		@include clearfix;
	}

	.block-company_title {
		margin: 0;
		float: left;

		h2 {
			margin: 0 0 12px;
		}
	}

	.block-company_button {
		min-width: 182px;
		float: right;
		margin-top: -22px;

		@media #{$mobile}{
			float: none;
			width: 100%;
			margin-top: 10px;
		}

		@media screen and (max-width: 480px) {

			.block-company_check-status + & {
				margin-top: 6px;
			}
		}
	}

	.block-company_check-status {

		float: left;
		height: 30px;
		line-height: 30px;
		margin: 0 16px 0 0;

		@media screen and (max-width: 480px) {
			margin-right: 10px;
			margin-bottom: 4px;
		}
	}
</style>
