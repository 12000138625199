<template>
	<div class="block-container">
		<div class="block-result block-result_no-border block-result_no-padding">
			<div class="block-result_title"><h2>Risicomatrix</h2></div>
			<div class="block-result_content">
				<div class="risk-matrix-container">
					<table aria-label="Risicomatrix" class="risk-matrix">
						<tbody>
							<tr>
								<th scope="col">{{ matrixTitle }}</th>

								<th v-for="label in matrixLabels" :key="label" scope="col">
									<span>
										<span class="risk-title_container">
											{{ label }}
										</span>
									</span>
								</th>

								<th scope="col"></th>
							</tr>
							<template v-if="matrixData.length > 0">
								<tr v-for="groupMatrix in matrixData" :key="groupMatrix.id">
									<td>
										<router-link v-if="getDetailPageRouterObject"
										             :to="getDetailPageRouterObject(groupMatrix.id)">
											{{ groupMatrix.name }}
										</router-link>
										<a v-else href="#" @click.prevent="goToDetail(groupMatrix.id)">
											{{ groupMatrix.name }}
										</a>
									</td>
									<td v-for="item in groupMatrix[labelKey]" :key="item.name">
										<icon-status :color="item.status" :hide-icon="true" />
									</td>
									<td class="risk-matrix_delete">
										<a v-if="hasWriteAccess"
										   :title="deleteGroupTitle"
										   href="#"
										   @click.prevent="deleteGroup(groupMatrix.id)">
											<em class="icon-delete"></em>
										</a>
										<a v-else class="disabled" href="#">
											<em class="icon-delete"></em>
										</a>
									</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td :colspan="matrixLabels.length + 2" class="risk-matrix_empty">
										<a v-if="hasWriteAccess"
										   href="#"
										   @click.prevent="goToAddFunctionGroup">
											Er zijn geen functiegroepen toegevoegd.
										</a>
										<template v-else>Er zijn geen functiegroepen toegevoegd.</template>
									</td>
								</tr>
							</template>

							<template v-if="genericResults">
								<tr>
									<th :colspan="matrixLabels.length + 2" scope="row">
										{{ genericResultsTitle }}
									</th>
								</tr>

								<tr v-for="result in genericResults" :key="result.name">
									<td>
										<router-link v-if="getDetailPageRouterObject"
										             :to="getDetailPageRouterObject(result.scriptKey)">
											{{ result.name }}
										</router-link>
										<a v-else href="#" @click.prevent="goToDetail(result.scriptKey)">
											{{ result.name }}
										</a>
									</td>

									<td :colspan="matrixLabels.length + 1">
										<icon-status :color="result.status" :hide-icon="true" />
									</td>
								</tr>

							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import IconStatus from '@/components/icons/IconStatus';

	export default {
		name: 'CheckResultMatrix',
		components: {IconStatus},
		props: {
			matrixLabels: {
				type: Array,
				required: true
			},
			matrixData: {
				type: Array,
				required: true
			},
			matrixTitle: {
				type: String,
				required: true
			},
			getDetailPageRouterObject: {
				type: Function,
				required: true
			},
			labelKey: {
				type: String,
				required: true
			},
			deleteGroupTitle: {
				type: String,
				default: 'Verwijder groep'
			},
			genericResults: {
				type: Array,
				default: () => []
			},
			genericResultsTitle: {
				type: String,
				default: 'Bedrijfsbreed'
			},
			hasWriteAccess: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			goToDetail(id) {
				throw new Error(`No router object is added to risk matrix. Cannot navigate to ${id}`);
			},
			goToAddFunctionGroup() {
				this.$emit('addGroup');
			},
			deleteGroup(id) {
				this.$emit('deleteGroup', id);
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.category-table_container {
		max-width: 475px;
	}

	.risk-matrix_empty {
		color: $grey;
	}

	.block-result.block-result-white {
		border-left: 2px solid #ffffff;
	}

	.block-result {
		background: #f7f7f7;
		margin: 2px 0;
		border-left: 2px solid $content-color;

		&.block-result_no-border {
			border-left: none;
		}
	}

	h2 {
		font-size: 20px;
		line-height: 24px;
		margin: 0 40px;
		padding: 8px 0;

		.block-result_no-padding & {
			padding-left: 20px;
			margin-left: 0;
		}
	}

	.block-result_content {
		padding: 14px 40px;
		border-top: 1px solid #eee;

		.block-result_no-padding & {
			padding-left: 0;
			padding-right: 0;
		}

		&:last-child {
			border-bottom: none;
		}

		@media #{$mobile} {
			padding: 8px 12px;
		}
	}

	.risk-matrix-container {
		width: 100%;


		& ~ .button-bar {
			margin: 20px 20px 6px;
		}
	}

	.risk-matrix {
		margin: 0;
		width: 100%;
		min-width: 600px;

		th {
			font-size: 12px;
			line-height: 20px;
			color: $grey;
			vertical-align: bottom;
			text-align: left;

			overflow: visible;

			padding: 40px 0 10px 30px;
			border-bottom: 1px solid #EEEEEE;

			&:first-child {
				padding-left: 20px;
			}

			span {
				width: 10px;
				display: block;
				padding: 0 0 0 2px;
				span {
					display: block;
					width: 90px;
					line-height: 1.42em;

					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-ms-transform: rotate(-45deg);
					transform: rotate(-45deg);

					-webkit-transform-origin: left bottom;
					-moz-transform-origin: left bottom;
					-ms-transform-origin: left bottom;
					transform-origin: left bottom;
				}
			}

		}

		td {
			vertical-align: middle;
			text-align: left;
			padding: 0 0 0 20px;
			line-height: 20px;
			height: 40px;

			&:first-child {
				padding-left: 20px;
			}

			&:last-child {
				padding-left: 10px;
			}

			border-bottom: 1px solid #EEEEEE;

			.status-icon {
				width: 20px;
				height: 20px;
				vertical-align: middle;
			}
		}

		& ~ .button-bar {
			margin: 20px 20px 6px;
		}

		.risk-matrix_delete {
			padding: 0;
			text-align: right;
			width: 40px;

			a {
				display: inline-block;
				width: 40px;
				text-align: center;

				&:hover {
					color: #000;
				}
			}
		}

		.risk-matrix_copy {
			padding: 0;
			text-align: right;
			width: 30px;

			a {
				display: inline-block;
				width: 30px;
				text-align: center;

				&:hover {
					color: #000;
				}
			}
		}
	}
</style>
