import {currentlyInSpaMode} from '../utils/integrationHelper';
import router from '../router';
import {Api} from '@/utils/api';
import {personRole} from '@/types/PersonRole';

const user = {
	namespaced: false,

	state: () => ({
		fullName: null,
		roles: [],
		id: null,
		isLoggedIn: false,
		loginExpiration: null
	}),

	getters: {
		userHasId: state => id => state.id === id,
		userHasRole: state => role => state.roles.includes(role),
		userHasRoleCoach: state => () => state.roles.includes(personRole.ROLE_VIJFXBETER_COACH),
		userIsLoggedIn: state => () => state.isLoggedIn
	},

	mutations: {
		login(state, loginResponse) {
			if (!loginResponse.loggedIn) {
				return this.logout(state, false);
			}

			state.isLoggedIn = true;
			state.loginExpiration = loginResponse.cookieExpiration;
			state.fullName = loginResponse.fullName;
			state.roles = loginResponse.personRoles;
			state.id = loginResponse.id;
			this.state.company.companyListObject = {};
		},

		logout(state, redirectToHomePage = true, reloadPage = true) {
			state.isLoggedIn = false;
			state.loginExpiration = null;
			state.fullName = null;
			state.roles = [];
			state.id = null;
			this.state.company.companyListObject = {};

			if (redirectToHomePage) {
				location.href = import.meta.env.VITE_APP_WEBSITE_DOMAIN + '/site';
				return;
			}

			if (currentlyInSpaMode()) {
				// Triggers some sort of reload which activates the proper route guarding
				router.go();
				return;
			}

			if (reloadPage) {
				location.reload();
			}
		}
	},

	actions: {
		checkSavedLoggedIn({commit, state}) {
			if (state.isLoggedIn !== true) {
				return;
			}

			if (new Date(state.loginExpiration) < new Date()) {
				commit('logout', false, false);
			}
		},

		checkLoginStatusWithAuthCookie({commit}) {
			Api.getLogin().then((response) => {
				if (response.responseObject.loggedIn !== true) {
					throw new Error('Not a successful login');
				}
				commit('login', response.responseObject);
			}).catch(() => {
				commit('logout', false);
			});
		}
	}
};

export default user;
