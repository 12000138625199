<template>
	<base-grid page-title="Wijzigen wachtwoord" sidebar="account">

		<div class="block-form-title">
			<h2>Wijzig hier je wachtwoord</h2>
		</div>

		<form-update-password />

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import FormUpdatePassword from './FormUpdatePassword';

	export default {
		name: 'PageUpdatePassword',
		components: {FormUpdatePassword, BaseGrid}
	};
</script>
