export default class NumberUtils {

	static stringToNumber = (value: string) => {
		if (value) {
			// A radix of 10 means we only parse numbers that match the decimal system.
			const radix = 10;
			return parseInt(value, radix);
		}
		return undefined;
	};

}
