<template>
	<base-grid page-title="Verbetercheck verplaatsen" sidebar="admin">
		<form-migrate-improvement-check />
	</base-grid>
</template>

<script>
import BaseGrid from '@/components/decorations/BaseGrid';
import FormMigrateImprovementCheck from '@/components/admin/company/FormMigrateImprovementCheck';

export default {
	name: 'PageMigrateImprovementCheck',
	components: {FormMigrateImprovementCheck, BaseGrid}
};
</script>
<style lang="scss">
	@import '@/assets/css/variables';

	.block-form {
		padding: 6px 20px 20px;
		background: #f6f6f6;
		margin: 0 0 20px;

		@media #{$mobile}{
			padding-left: 12px;
			padding-right: 12px;
		}
	}
</style>
