<template>
	<vuelidate-form :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                :warning-message="warningMessage"
	                cancel-button-text="Annuleren"
	                class="content-form content-form--edit-action"
	                submit-button-text="Advies archiveren"
	                @cancel="$emit('cancel')"
	                @validSubmit="handleSubmit">

		<p>Heeft het advies positief bijgedragen aan veilig en gezond werken?</p>
		<div class="checkbox-list">
			<radio-list-item v-model="form.safetyImproved"
			                 label="Ja"
			                 name="contribution"
			                 value="YES" />

			<radio-list-item v-model="form.safetyImproved"
			                 label="Nee"
			                 name="contribution"
			                 value="NO" />

			<radio-list-item v-model="form.safetyImproved"
			                 label="N.v.t."
			                 name="contribution"
			                 value="NA" />
		</div>

		<form-textarea v-if="showArchivedQuestion"
		               v-model.trim="form.safetyImprovementAnswer"
		               :validation="v$.form.safetyImprovementAnswer"
		               label="Je antwoord vereist een extra toelichting" />
	</vuelidate-form>
</template>

<script>
	import VuelidateForm from '@/components/forms/VuelidateForm';
	import FormTextarea from '@/components/forms/FormTextarea';
	import RadioListItem from '@/components/forms/RadioListItem';
	import {required, requiredIf} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormArchiveAdvice',
		components: {VuelidateForm, FormTextarea, RadioListItem},
		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				form: {
					safetyImproved: '',
					safetyImprovementAnswer: ''
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		props: {
			advice: {
				type: Object,
				default: () => ({})
			}
		},
		validations() {
			return {
				form: {
					safetyImproved: {
						required
					},
					safetyImprovementAnswer: {
						required: requiredIf(this.showArchivedQuestion)
					}
				}
			};
		},
		computed: {
			showArchivedQuestion() {
				return this.form.safetyImproved === 'NO' || this.form.safetyImproved === 'NA';
			}
		},
		methods: {
			closeDialog() {
				this.$store.dispatch('modals/archiveAdvice/closeDialog');
			},
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				this.toggleArchived();

				await Api.postUpdateAdvice(this.$route.params.companyId, this.form.id, this.form)
					.then(() => {
						this.$emit('submitted');
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Advies kon niet worden gearchiveerd';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			toggleArchived() {
				this.form.archived = !this.form.archived;
			},
			initForm() {
				this.form = this.advice;
			}
		},
		mounted() {
			this.initForm();
		}
	};
</script>

<style lang="scss" scoped>
	#modal-archive-advice {
		:deep(textarea) {
			width: 100%;
		}
	}
</style>
