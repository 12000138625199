<template>

	<vuelidate-form ref="formLogin"
	                :class="formClass"
	                :is-submitting="isSubmitting"
	                :submit-button-text="submitButtonText"
	                :vuelidate="v$"
	                :warning-message="warningMessage"
	                class="content-form"
	                @mouseleave="handleMouseLeave"
	                @validSubmit="handleSubmit">

		<form-input v-model.trim="v$.form.username.$model"
		            :hide-label="this.formMode !== 'inline'"
		            :validation="v$.form.username"
		            autocomplete="username"
		            label="E-mailadres"
		            name="username"
		            required
		            type="email" />

		<form-input v-model.trim="v$.form.password.$model"
		            :hide-label="this.formMode !== 'inline'"
		            :validation="v$.form.password"
		            autocomplete="current-password"
		            class="password-input-container"
		            label="Wachtwoord"
		            name="password"
		            required
		            type="password">

			<template v-slot:after-input>
				<link-mijn5xbeter class="popup-save-forgot-password"
				                  href="/wachtwoord-vergeten">Vergeten?
				</link-mijn5xbeter>
			</template>
		</form-input>

		<template v-if="showRegisterLink" v-slot:after-button-bar>
			<link-mijn5xbeter class="login-form-signup" href="/aanmelden">
				Nieuw hier? Meld je nu aan!
			</link-mijn5xbeter>
		</template>

	</vuelidate-form>

</template>

<script>
	import {email, required} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import {pageHelper} from '@/utils/pageHelper';
	import FormInput from '@/components/forms/FormInput';
	import VuelidateForm from '@/components/forms/VuelidateForm';
	import LinkMijn5xbeter from '@/components/LinkMijn5xbeter';
	import {currentlyInSpaMode} from '@/utils/integrationHelper';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormLogin',
		components: {LinkMijn5xbeter, VuelidateForm, FormInput},
		props: {
			formMode: {
				type: String,
				default: 'header'
			},
			showRegisterLink: {
				type: Boolean,
				default: false
			},
			registerHref: {
				type: String
			},
			redirect: {
				type: [String, Object]
			},
			submitButtonText: {
				type: String,
				default: 'Login'
			}
		},
		data() {
			return {
				isSubmitting: false,
				showSticky: false,
				warningMessage: '',
				form: {
					username: '',
					password: ''
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations: {
			form: {
				username: {
					required,
					email
				},
				password: {
					required
				}
			}
		},
		computed: {
			formClass() {
				if (this.formMode === 'mobile') {
					return 'mobile-login-form';
				}

				if (this.formMode === 'header') {
					return `header-login-form ${this.showSticky ? 'sticky-login-form' : ''}`;
				}

				return '';
			}
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				await Api.postLogin(this.form)
					.then((result) => {
						this.$store.commit('login', result.responseObject);
						if (this.$route.query.redirect) {
							this.redirectTo(this.$route.query.redirect);
							return;
						}
						if (this.$route.query.redirectToVc) {
							this.redirectToVc(this.$route.query.redirectToVc);
							return;
						}
						if (this.redirect) {
							this.redirectTo(this.redirect);
							return;
						}
						this.redirectToMyCompanyPage();
					})
					.catch((result) => {
						if (result.message && result.message.length > 0) {
							this.warningMessage = result.message;
							return;
						}
						this.warningMessage = 'Inloggen is niet gelukt';
					})
					.finally(() => {
						this.isSubmitting = false;
					});
			},
			handleMouseLeave() {
				// Don't close the loginform when one of the inputs or button has focus
				if (this.$refs.formLogin.$refs.form.contains(document.activeElement)) {
					return;
				}
				this.$emit('close');
			},
			redirectTo(redirect) {
				this.$router.push(redirect);
			},
			redirectToVc(redirect) {
				document.location.replace(import.meta.env.VITE_APP_VC_DOMAIN + redirect);
			},
			redirectToMyCompanyPage() {
				if (currentlyInSpaMode()) {
					this.$router.push({name: pageHelper.PAGE_MY_COMPANIES});
				} else {
					document.location.replace(`${import.meta.env.VITE_APP_MIJN5XBETER_BACKEND}${import.meta.env.VITE_APP_INTEGRATION_BASEURL}/bedrijven`);
				}
			}
		}
	};
</script>

<style lang="scss">
	@import '@/assets/css/_variables.scss';

	.header-login-form.header-login-form {
		right: 0;
		position: absolute;
		z-index: 2;
		background: $blue;
		padding: 10px;
		margin-top: 3px;
		width: 260px;

		.content-container & {
			max-width: 100%;
		}

		&.sticky-login-form {
			display: block !important;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			z-index: 0;
			top: -3px;
			bottom: -20px;
			left: -20px;
			right: -20px;
			background: rgba(255, 255, 255, 0);
		}

		input[type=text],
		input[type=email],
		input[type=password] {
			background: #ffffff;
			padding: 0 1em;
			font-size: 14px;
			color: #000000;
			border: 1px solid #ffffff;
			position: relative;
			z-index: 2;
			width: 100%;
			margin: 0 0 10px;
		}

		div.input-wrapper {
			position: relative;
			z-index: 3;

			&:hover {
				z-index: 4;
			}
		}

		div.password-input-container {
			position: relative;
			z-index: 3;
		}

		.button-bar {
			margin: 10px 0;
			position: relative;
			z-index: 2;
		}

		a.login-form-signup {
			position: relative;
			z-index: 2;
			display: block;
			text-align: center;
			color: #ffffff;
			text-decoration: none;
			line-height: 1.6;
			margin: 0;

			&:hover {
				text-decoration: underline;
			}
		}

		button {
			border-color: #ffffff;
			color: #ffffff;
			width: 100%;

			&:hover {
				border-color: #ffffff;
				background-color: #ffffff;
				color: $blue;
			}
		}
	}

	.popup-save-forgot-password {
		float: right;
		display: block;
		font-size: 11px;
		color: $blue;
		text-decoration: none;
		margin: -40px 15px 0 0;

		&:hover {
			text-decoration: underline;
		}

		input:focus + & {
			display: none;
		}
	}
</style>
