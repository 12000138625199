<template>
	<div class="block-result_content">
		<slot></slot>
	</div>
</template>
<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.block-result_content {
		padding: 14px 40px;
		border-top: 1px solid #eee;

		.block-result_no-padding & {
			padding-left: 0;
			padding-right: 0;
		}

		&:last-child {
			border-bottom: none;
		}

		@media #{$mobile} {
			padding: 8px 12px;
		}
	}
</style>
