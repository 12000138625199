<template>
	<notice-bar v-if="showNoticeBar" cssClass="invited-user-notice">
		{{ text }} als <strong>{{ roleTitle }}</strong>.
		<a href="#"
		   @click.prevent="openInfoDialog">Wat betekent dit?
		</a>
	</notice-bar>
</template>

<script>
	import NoticeBar from '../NoticeBar';
	import {inviteeRole} from '@/types/InviteeRole';

	const htmlContentForModal = `<p>
    <strong>Verbetercoach</strong><br>
    Je bent verbetercoach in het systeem.<br>
    <strong>Collega</strong><br>
    Je bent een collega van de eigenaar van deze verbetercheck en uitgenodigd om mee te kijken.<br>

    <strong>Externe adviseur</strong><br>
    Je bent een externe adviseur en de eigenaar van deze verbetercheck heeft jou uitgenodigd om mee te
    kijken.<br>

    <strong>Bewerken of meekijken</strong><br>
    Afhankelijk van de door degene die jou heeft uitgenodigd ingestelde waarden kun je gegevens alleen
    bekijken
    of ook bewerken.<br/>
    <br/>
   </p>`;

	export default {
		name: 'InvitedUserNotice',
		components: {NoticeBar},
		props: {
			scope: {
				type: String,
				default: 'company'
			},
			role: {
				type: String,
				validator: value => [
					inviteeRole.ROLE_EXTERNAL_ADVISOR,
					inviteeRole.ROLE_COLLEAGUE,
					inviteeRole.ROLE_COACH,
					inviteeRole.ROLE_COMPANY_OWNER
				].indexOf(value) !== -1
			},
			rights: {
				type: String,
				// eslint-disable-next-line
				validator: value => [
					'READONLY',
					'WRITE'
				].indexOf(value) !== -1
			}
		},
		computed: {
			roleTitle() {
				if (this.role === inviteeRole.ROLE_COACH) {
					return 'verbetercoach';
				}
				if (this.role === inviteeRole.ROLE_EXTERNAL_ADVISOR) {
					return 'externe adviseur';
				}

				return 'collega';
			},
			scopeTitle() {
				if (this.scope === 'check') {
					return 'deze verbetercheck';
				}

				return 'dit bedrijf';
			},
			showNoticeBar() {
				return this.role !== inviteeRole.ROLE_COMPANY_OWNER;
			},
			text() {
				if (this.rights === 'WRITE') {
					return `Je kijkt mee en kunt ${this.scopeTitle} bewerken`;
				}

				return `Je kijkt mee in ${this.scopeTitle}`;

			}
		},
		methods: {
			openInfoDialog() {
				this.$confirmDialog({
						title: 'Uitleg over meekijken',
						htmlContent: htmlContentForModal,
						content: '',
						confirmButton: 'Sluiten',
						cancelButton: ''
					})
					.catch(() => {
						// Do nothing
					});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.invited-user-notice {
		@media screen and (min-width: 1025px) {
			margin-top: -24px;
		}

		a {
			display: inline-block;
		}
	}
</style>
