<template>
	<ul>
		<li class=""><a href="/site/nl/hulpmiddelen"><span>Hulpmiddelen<i class="icon-chrevron-down"></i></span></a>
			<ul>
				<li class=""><a href="/site/nl/hulpmiddelen/alle-hulpmiddelen"><span>Alle hulpmiddelen</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/algemeen"><span>Algemeen</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/5xbeter-films"><span>5xbeter Films</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/fysieke-belasting"><span>Fysieke Belasting</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/gevaarlijke-stoffen"><span>Gevaarlijke Stoffen</span></a>
				</li>
				<li class=""><a href="/site/nl/hulpmiddelen/lasrook"><span>Lasrook</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/machineveiligheid"><span>Machineveiligheid</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/schadelijk-geluid"><span>Schadelijk Geluid</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/valgevaar"><span>Valgevaar</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/nieuwkomers"><span>Nieuwkomers</span></a></li>
				<li class="">
					<a href="/site/nl/hulpmiddelen/valgevaar-scheepsbouw"><span>Valgevaar Scheepsbouw</span></a></li>
				<li class=""><a href="/site/nl/hulpmiddelen/besloten-ruimtes-scheepsbouw"><span>Besloten Ruimtes Scheepsbouw</span></a>
				</li>
			</ul>
		</li>
		<li class=""><a href="/site/nl"><span>Thema's<i class="icon-chrevron-down"></i></span></a>
			<ul>
				<li class=""><a href="/site/nl/themas/fysieke-belasting"><span>Fysieke belasting</span></a>
					<ul>
						<li class=""><a href="/site/nl/themas/fysieke-belasting"><span>Introductie</span></a></li>
						<li class=""><a href="/site/nl/themas/fysieke-belasting/hulpmiddelen"><span>Hulpmiddelen</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/fysieke-belasting/pictogrammen"><span>Pictogrammen</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/fysieke-belasting/veelgestelde-vragen"><span>Veelgestelde vragen</span></a>
						</li>
					</ul>
				</li>
				<li class=""><a href="/site/nl/themas/gevaarlijke-stoffen"><span>Gevaarlijke stoffen</span></a>
					<ul>
						<li class=""><a href="[cms-url locale='nl' node='567']"><span>Introductie</span></a></li>
						<li class=""><a href="/gevaarlijkestoffen/start.web"><span>Verbetercheck</span></a></li>
						<li class="">
							<a href="/site/nl/themas/gevaarlijke-stoffen/hulpmiddelen"><span>Hulpmiddelen</span></a>
						</li>
						<li class="">
							<a href="/site/nl/themas/gevaarlijke-stoffen/pictogrammen"><span>Pictogrammen</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/gevaarlijke-stoffen/veelgestelde-vragen"><span>Veelgestelde vragen</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/gevaarlijke-stoffen/faq-validatieproces"><span>FAQ Validatieproces</span></a>
						</li>
					</ul>
				</li>
				<li class=""><a href="/site/nl/themas/lasrook"><span>Lasrook</span></a>
					<ul>
						<li class=""><a href="/site/nl/themas/lasrook"><span>Introductie</span></a></li>
						<li class=""><a href="/lasrook/survey/intro.web"><span>Verbetercheck</span></a></li>
						<li class=""><a href="/site/nl/themas/lasrook/hulpmiddelen"><span>Hulpmiddelen</span></a></li>
						<li class=""><a href="/site/nl/themas/lasrook/pictogrammen"><span>Pictogrammen</span></a></li>
						<li class="">
							<a href="/site/nl/themas/lasrook/veelgestelde-vragen"><span>Veelgestelde vragen</span></a>
						</li>
					</ul>
				</li>
				<li class=""><a href="/site/nl/themas/machineveiligheid"><span>Machineveiligheid</span></a>
					<ul>
						<li class=""><a href="/site/nl/themas/machineveiligheid"><span>Introductie</span></a></li>
						<li class=""><a href="/machine/survey.web?action=start"><span>Verbetercheck</span></a></li>
						<li class=""><a href="/site/nl/themas/machineveiligheid/hulpmiddelen"><span>Hulpmiddelen</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/machineveiligheid/pictogrammen"><span>Pictogrammen</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/machineveiligheid/veelgestelde-vragen"><span>Veelgestelde vragen</span></a>
						</li>
					</ul>
				</li>
				<li class=""><a href="/site/nl/themas/schadelijk-geluid"><span>Schadelijk geluid</span></a>
					<ul>
						<li class=""><a href="/site/nl/themas/schadelijk-geluid"><span>Introductie</span></a></li>
						<li class=""><a href="/geluid/startcheck.web"><span>Verbetercheck</span></a></li>
						<li class=""><a href="/site/nl/themas/schadelijk-geluid/hulpmiddelen"><span>Hulpmiddelen</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/schadelijk-geluid/pictogrammen"><span>Pictogrammen</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/schadelijk-geluid/veelgestelde-vragen"><span>Veelgestelde vragen</span></a>
						</li>
					</ul>
				</li>
				<li class=""><a href="/site/nl/themas/valgevaar"><span>Valgevaar</span></a>
					<ul>
						<li class=""><a href="/site/nl/themas/valgevaar"><span>Introductie</span></a></li>
						<li class=""><a href="/werkenophoogte/survey.web?action=start"><span>Verbetercheck</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/valgevaar/hulpmiddelen"><span>Hulpmiddelen</span></a></li>
						<li class=""><a href="/site/nl/themas/valgevaar/pictogrammen"><span>Pictogrammen</span></a></li>
						<li class="">
							<a href="/site/nl/themas/valgevaar/veelgestelde-vragen"><span>Veelgestelde vragen</span></a>
						</li>
					</ul>
				</li>
				<li class=""><a href="/site/nl/themas/overige-themas"><span>Overige thema's</span></a>
					<ul>
						<li class=""><a href="[cms-url locale='nl' node='645']"><span>Introductie</span></a></li>
						<li class=""><a href="/site/nl/themas/overige-themas/nieuwkomers"><span>Nieuwkomers</span></a>
						</li>
						<li class="">
							<a href="/site/nl/themas/overige-themas/werken-in-besloten-ruimten-in-de-scheepsbouw"><span>Werken in besloten ruimten in de scheepsbouw</span></a>
						</li>
						<li class=""><a href="/site/nl/themas/overige-themas/werken-op-hoogte-in-de-scheepsbouw"><span>Werken op hoogte in de scheepsbouw</span></a>
						</li>
						<li class=""><a href="[cms-url locale='nl' node='998']"><span>Hulpmiddelen</span></a></li>
						<li class=""><a href="/site/nl/themas/overige-themas/veelgestelde-vragen"><span>Veelgestelde vragen</span></a>
						</li>
					</ul>
				</li>
			</ul>
		</li>
		<li class=""><a href="/site/nl/nieuws"><span>Nieuws<i class="icon-chrevron-down"></i></span></a>
			<ul>
				<li class=""><a href="/site/nl/nieuws/nieuwsbrief"><span>Nieuwsbrief</span></a></li>
			</ul>
		</li>
		<li class=""><a href="/site/nl/over-5xbeter"><span>Over 5xbeter<i class="icon-chrevron-down"></i></span></a>
			<ul>
				<li class=""><a href="/site/nl/over-5xbeter/5-partijen"><span>5 partijen</span></a></li>
				<li class=""><a href="/site/nl/over-5xbeter/verbetercoaches"><span>Verbetercoaches</span></a></li>
				<li class="">
					<a href="/site/nl/over-5xbeter/arbowet-en-arbocatalogus"><span>Arbowet en arbocatalogus</span></a>
				</li>
				<li class=""><a href="/site/nl/over-5xbeter/contact"><span>Contact</span></a></li>
				<li class=""><a href="/site/nl/over-5xbeter/5xbeter-event"><span>5xbeter-event</span></a></li>
				<li class=""><a href="/site/nl/over-5xbeter/agenda"><span>Agenda</span></a></li>
			</ul>
		</li>
		<li class=""><a href="/site/nl/faq"><span>FAQ</span></a></li>
		<li class="header-search"><a class="js-toggle-header-search"
		                             href="#"><span><i class="icon-search"></i></span></a>
			<form id="searchForm"
			      action="http://127.0.0.1:8081/site/nl/search"
			      class="header-search-form"
			      method="get"
			      target="_top">
				<div><input id="searchText" name="filter" placeholder="Search..." type="search">
					<button type="submit"><i class="icon-search"></i></button>
				</div>
			</form>
		</li>
	</ul>
</template>

<script>
	/**
	 * Component with static fallback HTML code for vijfxbeter-header-menu-items component.
	 */
	export default {
		name: 'vijfxbeter-header-menu-items-fallback'
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/css/_variables.scss";
	@import "@/assets/css/_mixins.scss";
	@import "@/assets/css/_global.scss";
	@import "@/assets/css/website/_header.scss";
</style>


