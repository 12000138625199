<template>
	<div v-if="loading" title="Gegevens worden geladen">
		<skeleton-content-form title="Mijn gegevens" :rows="3"  />
		<skeleton-content-form title="Mijn 5xbeter account"  :columns="1" :rows="1" />
		<skeleton-content-form title="5xbeter nieuwsbrief" :columns="1" :rows="1" />
	</div>


	<p v-else-if="loadingFailed && warningMessage">
		{{warningMessage}}
	</p>

	<vuelidate-form :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                @validSubmit="handleSubmit"
	                class="content-form"
	                hide-button-bar
	                v-else>
		<div class="block-form-title">
			<h2>Mijn gegevens</h2>
		</div>

		<div class="block-form">
			<div class="row">
				<div class="col-6 col-sm-12">
					<form-input :validation="v$.form.firstName"
					            label="Voornaam"
					            maxlength="255"
					            required
					            v-model="v$.form.firstName.$model" />

					<form-input :validation="v$.form.middleName"
					            label="Tussenvoegsel"
					            maxlength="255"
					            placeholder="Tussenvoegsel"
					            v-model="v$.form.middleName.$model" />

					<form-input :validation="v$.form.lastName"
					            label="Achternaam"
					            maxlength="255"
					            required
					            v-model="v$.form.lastName.$model" />
				</div>

				<div class="col-6 col-sm-12">

					<form-select :options="accountBranchOptions"
					             label="Branche"
					             v-model="selectedBranch">
					</form-select>

					<form-input :validation="v$.branchTextInput"
					            label="Anders"
					            maxlength="255"
					            placeholder="Voer hier je branche in"
					            v-if="showOtherBranchInput"
					            v-model="v$.branchTextInput.$model" />

					<form-select :options="accountFunctionOptions"
					             label="Functie"
					             :add-empty-option="true"
					             v-model="form.function" />

				</div>
			</div>
		</div>

		<div class="block-form-title">
			<h2>Mijn 5xbeter account</h2>
		</div>

		<div class="block-form">
			<div class="row">
				<div class="col-12">
					<label for="emailAddressinput">E-mailadres *</label>
				</div>
				<div class="col-6 col-sm-12">
					<form-input :validation="v$.form.emailAddress"
					            hide-label
					            id="emailAddressinput"
					            label="E-mailadres"
					            maxlength="255"
					            required
					            type="email"
					            v-model="v$.form.emailAddress.$model" />
				</div>
				<div class="col-6 col-sm-12">
					<router-link class="button button-no-border" :to="getChangePasswordRoute()">
						<i class="icon-chevron-right"></i>Wachtwoord wijzigen
					</router-link>
				</div>
			</div>
		</div>

		<div class="block-form-title">
			<h2>5xbeter nieuwsbrief</h2>
		</div>

		<div class="block-form">
			<p>
				Wil je automatisch het nieuws over 5xbeter ontvangen?
				Schrijf je dan hieronder in voor de nieuwsbrief.
			</p>
			<div class="checkbox-list" style="max-width: 260px;">
				<div>
					<input id="receiveNewsletter"
					       style="width: auto;"
					       type="checkbox"
					       v-model="form.receivesNewsletter" />
					<label for="receiveNewsletter">Nieuwsbrief ontvangen</label>
				</div>
			</div>
		</div>

		<form-warning-block :message="warningMessage" v-if="warningMessage" />

		<div class="block-form-buttons">
			<div class="row">
				<div class="col-6 col-sm-12">
					<button class="button" type="submit">Opslaan</button>
				</div>

				<div class="col-6 col-sm-12">
					<p class="text-right text-grey">
						Velden met * zijn verplicht
					</p>
				</div>
			</div>
		</div>

	</vuelidate-form>
</template>

<script>
	import {email, maxLength, required} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import FormInput from '../forms/FormInput';
	import VuelidateForm from '../forms/VuelidateForm';
	import FormSelect from '../forms/FormSelect';
	import FormWarningBlock from '../forms/FormWarningBlock';
	import {
		accountBranchOptions,
		accountFunctionOptions,
		branchIsOther,
		branchNotFromList
	} from '@/utils/accountHelper';
	import {pageHelper} from '@/utils/pageHelper';
	import SkeletonContentForm from '../generic/SkeletonContentForm';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormMyAccount',
		components: {SkeletonContentForm, FormWarningBlock, FormSelect, VuelidateForm, FormInput},
		data() {
			return {
				loading: true,
				loadingFailed: false,
				isSubmitting: false,
				warningMessage: '',
				showSticky: false,
				form: {
					firstName: '',
					middleName: '',
					lastName: '',
					branch: '',
					function: '',
					emailAddress: '',
					receivesNewsletter: ''
				},
				selectedBranch: '',
				branchTextInput: ''
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations: {
			form: {
				firstName: {
					required,
					maxLength: maxLength(255)
				},
				middleName: {
					maxLength: maxLength(255)
				},
				lastName: {
					required,
					maxLength: maxLength(255)
				},
				emailAddress: {
					required,
					email
				}
			},
			branchTextInput: {
				maxLength: maxLength(255)
			}
		},
		computed: {
			accountFunctionOptions() {
				return accountFunctionOptions;
			},
			accountBranchOptions() {
				return accountBranchOptions;
			},
			showOtherBranchInput() {
				return branchIsOther(this.selectedBranch) || branchNotFromList(this.selectedBranch);
			}
		},
		watch: {
			selectedBranch(newValue, oldValue) {
				if (newValue === 'Anders' && oldValue !== '') {
					this.branchTextInput = '';
				} else if (newValue === 'Anders') {
					return;
				} else {
					this.branchTextInput = newValue;
				}
			}
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';

				const formObject = this.form;
				formObject.branch = this.branchTextInput;

				await Api.postUpdateUser(this.form)
					.then(() => {
						this.$router.push({
							name: pageHelper.PAGE_MY_COMPANIES
						});
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Het is niet gelukt de gegevens op te slaan';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			async getAccountData() {
				this.loading = true;
				await Api.getUserDetail().then((response) => {
					this.form = response.responseObject;
					this.branchTextInput = response.responseObject.branch;
					if (branchNotFromList(this.branchTextInput)) {
						this.selectedBranch = 'Anders';
					} else {
						this.selectedBranch = this.branchTextInput;
					}
				}).catch(() => {
					this.warningMessage = 'Gegevens ophalen is niet gelukt';
					this.loadingFailed = true;
				}).finally(() => {
					this.loading = false;
				});
			},
			getChangePasswordRoute() {
				return {name: pageHelper.PAGE_UPDATE_PASSWORD};
			}
		},
		mounted() {
			this.getAccountData();
		}
	};
</script>
