<template>
	<div class="block-page-header">
		<slot name="icon"></slot>
		<h1 class="block-page-header-title">
			<strong>
				<slot>&nbsp;</slot>
			</strong>
		</h1>
		<slot name="afterTitle"></slot>
	</div>
</template>
<script>
	export default {
		name: 'BlockPageHeader'
	};
</script>
<style lang="scss" scoped>
	@import '@/assets/css/variables';
	@import '@/assets/css/mixins';

	.block-page-header {

		@include clearfix;
	}

	.block-page-header-title {
		font-size: 24px;
		margin: 12px 0;
		padding: 0;

		@media #{$mobile} {
			font-size: 20px;
			margin-bottom: 14px;
		}

	}
</style>
