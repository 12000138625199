<template>
	<base-grid :page-title="companyTitle"
	           sidebar="company">

		<p v-if="warningMessage">
			{{ warningMessage }}
		</p>

		<div v-if="loading" class="block-container" title="Overzicht wordt geladen">
			<skeleton-improvement-check-overview />
		</div>

		<div v-if="!loading && companyData.name" class="block-container">
			<div v-if="isCompanyOwner" class="block-link">
				<router-link :to="editCompanyUrl">
					<em class="icon-chevron-right"></em>Bedrijfsgegevens aanpassen
				</router-link>
			</div>

			<company-check-list-item v-for="check in companyData.checks"
			                         :key="`${check.check}`"
			                         :check="check"
			                         :company-id="companyId"
			                         :hasWriteAccess="hasWriteAccess"
			                         @showWarningMessage="setWarningMessage"
			/>

			<company-people-overview v-if="isCompanyOwner"
			                         :companyData="companyData"
			                         @update-invitees="updateInvitees"
			                         @update-coach-invite="updateCoachInvite" />

			<company-delete-check v-if="isCompanyOwner"
			                      :checks="companyData.checks"
			                      :company-id="companyId" />

		</div>
	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import {pageHelper} from '@/utils/pageHelper';
	import {Api} from '@/utils/api';
	import CompanyCheckListItem from './CompanyCheckListItem';
	import CompanyPeopleOverview from './CompanyPeopleOverview';
	import SkeletonImprovementCheckOverview from './SkeletonImprovementCheckOverview';
	import CompanyDeleteCheck from '@/components/company/CompanyDeleteCheck';

	export default {
		name: 'PageCompanyImprovementCheckOverview',
		components: {
			CompanyDeleteCheck,
			SkeletonImprovementCheckOverview,
			CompanyPeopleOverview,
			CompanyCheckListItem,
			BaseGrid
		},
		data() {
			return {
				loading: true,
				warningMessage: '',
				companyData: {}
			};
		},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		computed: {
			companyTitle() {
				return this.companyData.name || this.$store.getters.companyNameById(this.companyId);
			},
			editCompanyUrl() {
				return {name: pageHelper.PAGE_COMPANY_DETAILS, params: {companyId: this.companyId}};
			},
			isCompanyOwner() {
				return this.$store.getters.isUserCompanyOwner(this.companyId);
			},
			hasWriteAccess() {
				return this.companyData.permission?.hasWriteAccess === true;
			}
		},
		methods: {
			async loadCompanyChecksData() {
				await Api.getCompanyChecksOverview(this.companyId)
					.then((response) => {
						this.companyData = response.responseObject;
						this.$store.dispatch('updateCompany', {
							id: this.companyId,
							externalId: this.companyData.externalId,
							name: this.companyData.name,
							permission: {...this.companyData.permission},
							checks: [...this.companyData.checks]
						});
					})
					.catch(() => this.warningMessage = 'Overzicht van verbeterchecks kon niet geladen worden')
					.finally(() => this.loading = false);
			},
			setWarningMessage(message) {
				this.warningMessage = message;
			},
			initPage() {
				this.loading = true;
				this.loadCompanyChecksData();
			},
			resetPage() {
				this.companyData = {};
			},
			updateInvitees(invitees) {
				this.companyData.invitees = invitees;
			},
			updateCoachInvite(coachInvite) {
				this.companyData.permission.coachInvited = coachInvite;
			}
		},
		watch: {
			$route() {
				this.resetPage();
				this.initPage();
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
<style lang="scss" scoped>
	@import '@/assets/css/variables';
	@import '@/assets/css/mixins';

	.block-link {
		margin: 2px 0;
		background: #fff;

		a {
			display: inline-block;
			padding: 0.5em 1em 0.5em 24px;

			@media #{$mobile}{
				display: block;
				padding-left: 15px;
			}

			.icon-chevron-right {
				margin-right: 8px;
			}
		}
	}

	.block-company-overview-check {
		background: #ffffff;
		padding: 15px 15px;

		max-height: 70px;
		@include clearfix;

		margin: 0 0 2px;

		&:last-child {
			margin-bottom: 0;
		}

		@media #{$mobile}{
			max-height: none;
		}
	}
</style>
