<template>
	<div class="checkbox-container-block">

		<error-wrapper v-if="validation && validation.$error" :for="inputId">
			{{ validationMessage }}
		</error-wrapper>

		<input :id="id"
		       :checked="shouldBeChecked"
		       :class="validation && validation.$error ? 'error' : ''"
		       :false-value="falseValue"
		       :name="name"
		       :required="required"
		       :true-value="trueValue"
		       :value="trueValue"
		       type="checkbox"
		       @change="updateInput">
		<slot name="hiddenField"></slot>
		<label :for="id">
			<slot>{{ label }}</slot>
		</label>

	</div>
</template>

<script>

	import {getFirstInvalidValidationMessage} from '@/utils/validatonMessages';
	import ErrorWrapper from '@/components/forms/ErrorWrapper';

	export default {
		name: 'FormCheckboxBlock',
		components: {ErrorWrapper},
		model: {
			prop: 'modelValue',
			event: 'change'
		},
		props: {
			label: {
				type: String
			},
			required: {
				type: Boolean,
				default: false
			},
			modelValue: {
				default: ''
			},
			value: {
				default: ''
			},
			id: {
				type: String
			},
			validation: {
				type: Object
			},
			trueValue: {
				default: true
			},
			falseValue: {
				default: false
			},
			name: {
				type: String
			}
		},
		computed: {
			validationMessage() {
				const validationObject = this.validation;
				if (validationObject === null) {
					return null;
				}
				return getFirstInvalidValidationMessage(validationObject);
			},
			shouldBeChecked() {
				if (this.modelValue instanceof Array) {
					return this.modelValue.includes(this.value);
				}
				// Note that `true-value` and `false-value` are camelCase in the JS
				return this.modelValue === this.trueValue;
			}
		},
		methods: {
			updateInput(event) {
				const isChecked = event.target.checked;

				if (this.modelValue instanceof Array) {
					const newValue = [...this.modelValue];

					if (isChecked) {
						newValue.push(this.value);
					} else {
						newValue.splice(newValue.indexOf(this.value), 1);
					}

					this.$emit('update:modelValue', newValue);
				} else {
					this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

	@import '@/assets/css/variables';

	.checkbox-container-block {

		input:not([type="hidden"]):not(:checked) ~ label:before {
			box-shadow: inset 1px 1px 0 $grey, inset -1px -1px 0 $grey;
		}

		label {
			background: $color-body-bg;
		}
	}

</style>
