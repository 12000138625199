<template>
  <base-grid page-title="Stap 5: Verbeter"
             sidebar="company">
    <div class="block-container block-improve">
      <h2 class="block-improve_title">Verbeter de veiligheid binnen jouw bedrijf</h2>

      <p>
        Verbeter en borg de veiligheid en gezondheid binnen jouw organisatie met behulp van de Mijn 5xbeter
        omgeving.
      </p>

      <span class="block-improve_subject-title">Adviezen</span>
      <p>
        Hier kun je zelf adviezen aanmaken en archiveren. Wanneer je een Verbetercoach hier toestemming
        voor geeft kan hij hier ook zijn adviezen voor jouw organisatie toevoegen.
      </p>

      <span class="block-improve_subject-title">Actiepunten</span>
      <p>Maak actiepunten aan op veiligheidsthema en koppel er een actiehouder en planning aan.</p>

      <span class="block-improve_subject-title">Bedrijfsdossier</span>
      <p>
        Deze omgeving biedt je de mogelijkheid om per thema een eigen bedrijfsdossier op te bouwen. Je
        kunt hier al je documentatie opslaan van tekstdocumenten tot films.
      </p>

      <span class="block-improve_subject-title">Bezoekverslagen</span>
      <p>
        Bij het onderdeel bezoekverslagen kun je een Verbetercoach toestemming geven om bezoekverslagen direct in jouw
        Mijn 5xbeter omgeving in te vullen. Je vindt hier bovendien een feedback mogelijkheid, waarmee je het bezoek en
        het verslag van de Verbetercoach kunt beoordelen. Hiermee kan de Verbetercoach zijn support aan jou verder
        verbeteren.
      </p>
    </div>
  </base-grid>
</template>

<script>
import BaseGrid from '../decorations/BaseGrid';

export default {
  name: 'PageImprove',
  components: {BaseGrid}
};
</script>
<style lang="scss" scoped>
	.block-improve {
		background: #fff;
		padding: 30px;
	}

	.block-improve .block-improve_title {
		font-weight: 700;
	}

	.block-improve .block-improve_subject-title {
		color: #00a0db;
	}
</style>
