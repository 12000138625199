<template>
	<div class="col-submenu">
		<h2 class="submenu-header">Fysieke belasting</h2>
		<ul class="submenu">
			<li>
				<back-link />
			</li>
		</ul>

		<ul class="submenu">
			<skeleton-submenu-item v-if="loading" />
			<template v-else>
				<li v-for="group in currentFunctionGroups" :key="group.id">
					<router-link :to="getRouterLinkForFunctionGroup(group)">
						<icon-status :color="group.status" />
						<span>{{ group.name }}</span>
					</router-link>
				</li>
			</template>
		</ul>

	</div>
</template>

<script lang="ts" setup>
	import {pageHelper} from '@/utils/pageHelper';
	import IconStatus from '@/components/icons/IconStatus.vue';
	import SkeletonSubmenuItem from '@/components/generic/SkeletonSubmenuItem.vue';
	import NumberUtils from '@/utils/numberUtils';
	import {checkStoreMethod} from '@/store/check/checkStore';
	import {PHYSICAL_STRAIN_STORE} from '@/store/check/physicalstrain/physicalStrainMethods';
	import BackLink from '@/components/decorations/BackLink.vue';
	import {computed, onMounted, ref} from 'vue';
	import {useRoute} from 'vue-router';
	import {IFunctionGroup} from '@/types/check/IFunctionGroup';
	import {useStore} from 'vuex';

	interface Props {
		functionGroups?: IFunctionGroup[]
	}

	const props = withDefaults(defineProps<Props>(), {
		functionGroups: () => []
	});

	const loading = ref(false);
	const loadedFunctionGroups = ref<IFunctionGroup[]>([]);

	const route = useRoute();
	const store = useStore();
	const companyId = computed(() => NumberUtils.stringToNumber(String(route.params.companyId)));
	const currentFunctionGroups = computed(() => {
		if (props.functionGroups?.length > 0) {
			return props.functionGroups;
		}
		return loadedFunctionGroups.value;
	});

	const getRouterLinkForFunctionGroup = (functionGroup: IFunctionGroup) => ({
		name: pageHelper.PAGE_PHYSICAL_RESULTS_FUNCTION_GROUP,
		params: {
			companyId: companyId.value,
			functionGroupIdOrScriptKey: functionGroup.id
		}
	});

	const loadFunctionGroups = async () => {
		const isResultOverviewPage = route.name === pageHelper.PAGE_PHYSICAL_RESULTS;

		if (props.functionGroups?.length > 0 || isResultOverviewPage) {
			return;
		}

		loading.value = true;

		await store.dispatch(PHYSICAL_STRAIN_STORE.GET_RESULTS, companyId.value)
			.then(results => loadedFunctionGroups.value = results.checkSections)
			.catch(() => store.dispatch(checkStoreMethod.SET_WARNING, 'Functiegroepen konden niet worden geladen.'))
			.finally(() => loading.value = false);
	};

	onMounted(() => loadFunctionGroups());
</script>
<style lang="scss" scoped>
	.status-icon {
		width: 20px;
		height: 20px;
		--status-font-size: 8px;
	}
</style>
