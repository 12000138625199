<template>
	<ul v-if="!offcanvas">
		<li class="header-shopping-cart">
			<link-shoppingcart-header />
		</li>

		<li :class="showAccountDropdown ? 'active' : ''"
		    class="header-login"
		    @mouseover="showAccountDropdown = true">
			<template v-if="userIsLoggedIn">
				<link-mijn5xbeter href="/bedrijven">
				<span>Mijn 5xbeter -
				<span :title="userName"
				      class="header-user-name">{{ userName }}</span> <i class="icon-chrevron-down"></i></span>
				</link-mijn5xbeter>

				<ul>
					<li>
						<link-mijn5xbeter href="/bedrijven"><span>Mijn bedrijven</span></link-mijn5xbeter>
					</li>
					<li>
						<link-mijn5xbeter href="/gegevens"><span>Mijn gegevens</span></link-mijn5xbeter>
					</li>
					<li>
						<link-mijn5xbeter href="/bestellingen"><span>Mijn bestellingen</span></link-mijn5xbeter>
					</li>
					<li>
						<logout-button htmlElement="a"><span>Log uit</span></logout-button>
					</li>
				</ul>
			</template>

			<template v-else>
				<a class="js-toggle-login-form"
				   href="#"
				   @click.prevent="showAccountDropdown = !showAccountDropdown"><span>Log in <i class="icon-chrevron-down"></i></span></a>
				<form-login v-show="showAccountDropdown"
				            :register-href="registrationLink"
				            show-register-link
				            @close="showAccountDropdown = false" />
			</template>
		</li>
	</ul>
	<ul v-else>
		<template v-if="userIsLoggedIn">
			<li>
				<h3>
					<link-mijn5xbeter href="/bedrijven">{{ userName }}</link-mijn5xbeter>
				</h3>
			</li>
			<li>
				<h3>
					<link-mijn5xbeter href="/bedrijven">
						Mijn 5xbeter
					</link-mijn5xbeter>
				</h3>

				<ul>
					<li>
						<link-mijn5xbeter href="/bedrijven"><span>Mijn bedrijven</span></link-mijn5xbeter>
					</li>
					<li>
						<link-mijn5xbeter href="/gegevens"><span>Mijn gegevens</span></link-mijn5xbeter>
					</li>
					<li>
						<link-mijn5xbeter href="/bestellingen"><span>Mijn bestellingen</span></link-mijn5xbeter>
					</li>
					<li>
						<logout-button htmlElement="a"><span>Log uit</span></logout-button>
					</li>
				</ul>
			</li>
		</template>
		<template v-if="!userIsLoggedIn">
			<li>
				<h3>Mijn 5xbeter</h3>
			</li>
			<li>
				<form-login form-mode="mobile"></form-login>
			</li>
			<li>
				<link-mijn5xbeter class="login-form-signup  text-center" href="/aanmelden">
					Nieuw hier? Meld je nu aan!
				</link-mijn5xbeter>
			</li>
		</template>
		<li class="seperator"></li>
		<li class="offcanvas-shoppingcart">
			<link-shoppingcart-header></link-shoppingcart-header>
		</li>
	</ul>
</template>

<script>
	import LinkMijn5xbeter from '@/components/LinkMijn5xbeter.vue';
	import FormLogin from '@/components/login/FormLogin.vue';
	import LinkShoppingcartHeader from '@/components/orders/LinkShoppingcartHeader';
	import LogoutButton from '@/components/LogoutButton';

	export default {
		name: 'vijfxbeter-account',
		components: {LogoutButton, LinkShoppingcartHeader, LinkMijn5xbeter, FormLogin},
		data() {
			return {
				showAccountDropdown: false
			};
		},
		props: {
			offcanvas: {
				type: Boolean,
				default: false
			},
			registrationLink: {
				type: String
			}
		},
		computed: {
			userIsLoggedIn() {
				return this.$store.state.user.isLoggedIn;
			},
			userName() {
				return this.$store.state.user.fullName;
			}
		},
		mounted() {
			this.$store.dispatch('checkSavedLoggedIn');
		}
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/css/_variables.scss";
	@import "@/assets/css/_mixins.scss";
	@import "@/assets/css/website/_header.scss";

	.header-user-name {
		font-weight: normal;
	}
</style>
