<template>
	<div class="col-submenu">
		<h2 class="submenu-header">Mijn 5xbeter</h2>
		<ul class="submenu">
			<li>
				<back-link />
			</li>
		</ul>

		<ul class="submenu">
			<li>
				<router-link :to="{ name: pageHelper.PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW, params: { companyId: companyId }}">
					Overzicht verbeterchecks
				</router-link>
			</li>
			<li v-if="showCompanyDetailsMenu.value">
				<router-link :to="{ name: pageHelper.PAGE_COMPANY_DETAILS, params: { companyId: companyId}}">
					Bedrijfsgegevens
				</router-link>
			</li>
		</ul>

		<sidebar-step5-menu />
	</div>
</template>
<script lang="ts" setup>
	import {pageHelper} from '@/utils/pageHelper';
	import SidebarStep5Menu from '@/components/decorations/SidebarStep5Menu.vue';
	import BackLink from '@/components/decorations/BackLink.vue';
	import {computed} from 'vue';
	import {useRoute} from 'vue-router';
	import {useStore} from 'vuex';

	const route = useRoute();
	const store = useStore();
	const companyId = computed(() => route.params.companyId);
	const userIsOwnerForCompany = computed(() => store.getters.isUserCompanyOwner(companyId.value));
	const showCompanyDetailsMenu = computed(() => userIsOwnerForCompany);
</script>

