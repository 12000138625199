import {pageHelper} from '@/utils/pageHelper';
import {inviteeRole} from '@/types/InviteeRole';
import {personRole} from '@/types/PersonRole';
import {rights} from '@/types/Rights';
import {check} from '@/types/Check';

const PageFunctionGroup = () => import('@/components/check/physicalstrain/PageFunctionGroup.vue');
const PageAddEditFunctionGroup = () => import('@/components/check/physicalstrain/PageAddEditFunctionGroup.vue');
const CheckPhysicalEndOfFunctionGroup = () => import('@/components/check/physicalstrain/CheckPhysicalEndOfFunctionGroup.vue');
const CheckPhysicalEnd = () => import('@/components/check/physicalstrain/CheckPhysicalEnd.vue');
const PageCheckPhysical = () => import('@/components/check/physicalstrain/PageCheckPhysical.vue');

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: '',
		name: pageHelper.PAGE_CHECK_PHYSICAL_STRAIN,
		component: PageCheckPhysical,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			inviteeRoles: [
				inviteeRole.ROLE_COACH,
				inviteeRole.ROLE_COLLEAGUE,
				inviteeRole.ROLE_EXTERNAL_ADVISOR,
				inviteeRole.ROLE_COMPANY_OWNER
			],
			check: check.PHYSICAL_STRAIN,
			rights: rights.WRITE,
			improvementCheckFullScreen: true,
			progressStep: '3'
		}
	},
	{
		path: '',
		name: pageHelper.PAGE_CHECK_PHYSICAL_END_OF_FUNCTION_GROUP,
		component: CheckPhysicalEndOfFunctionGroup,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			inviteeRoles: [
				inviteeRole.ROLE_COACH,
				inviteeRole.ROLE_COLLEAGUE,
				inviteeRole.ROLE_EXTERNAL_ADVISOR,
				inviteeRole.ROLE_COMPANY_OWNER
			],
			check: check.PHYSICAL_STRAIN,
			rights: rights.WRITE,
			improvementCheckFullScreen: true,
			progressStep: '3'
		}
	},
	{
		path: '',
		name: pageHelper.PAGE_CHECK_PHYSICAL_END,
		component: CheckPhysicalEnd,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			inviteeRoles: [
				inviteeRole.ROLE_COACH,
				inviteeRole.ROLE_COLLEAGUE,
				inviteeRole.ROLE_EXTERNAL_ADVISOR,
				inviteeRole.ROLE_COMPANY_OWNER
			],
			check: check.PHYSICAL_STRAIN,
			rights: rights.WRITE,
			improvementCheckFullScreen: true,
			progressStep: '3'
		}
	},
	{
		path: 'functiegroepen',
		name: pageHelper.PAGE_PHYSICAL_FUNCTION_GROUP,
		component: PageFunctionGroup,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			inviteeRoles: [
				inviteeRole.ROLE_COACH,
				inviteeRole.ROLE_COLLEAGUE,
				inviteeRole.ROLE_EXTERNAL_ADVISOR,
				inviteeRole.ROLE_COMPANY_OWNER
			],
			check: check.PHYSICAL_STRAIN,
			rights: rights.WRITE,
			improvementCheckFullScreen: true,
			progressStep: '3'
		}
	},
	{
		path: 'functiegroep',
		name: pageHelper.PAGE_PHYSICAL_ADD_EDIT_FUNCTION_GROUP,
		component: PageAddEditFunctionGroup,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			inviteeRoles: [
				inviteeRole.ROLE_COACH,
				inviteeRole.ROLE_COLLEAGUE,
				inviteeRole.ROLE_EXTERNAL_ADVISOR,
				inviteeRole.ROLE_COMPANY_OWNER
			],
			check: check.PHYSICAL_STRAIN,
			rights: rights.WRITE,
			improvementCheckFullScreen: true,
			progressStep: '3'
		}
	}
];
