<template>
    <div>
        <icon-check :check="check.check" />
        <icon-status :color="check.checkStatus"
                     :number="check.numUnfinished"
                     :hideIcon="hideIcon" />
    </div>
</template>

<script>

    import IconCheck from '../icons/IconCheck';
    import IconStatus from '../icons/IconStatus';
    import {checkType} from '@/utils/checkHelper';

    export default {
        name: 'CheckStatus',
        components: {IconStatus, IconCheck},
        props: {
            check: {
                type: Object,
                required: true
            }
        },
        computed: {
            hideIcon() {
                return this.check.checkType !== checkType.REGISTER && this.check.numUnfinished > 0;
            }
        }
    };
</script>
<style lang="scss" scoped>
	@import '@/assets/css/variables';

	.block-company_title + .block-company_check-status {
		clear: left;
	}

	.icon-werkdruk,
	.icon-lasrook,
	.icon-machineveiligheid,
	.icon-machine,
	.icon-schadelijkgeluid,
	.icon-geluid,
	.icon-fysiekebelasting,
	.icon-hoogte,
	.icon-werkenophoogte,
	.icon-gevaarlijkestoffen {
		font-size: var(--status-size, 30px);
		position: absolute;
		z-index: 2;
	}

	.status-icon {
		margin-left: 24px;
	}

	.status-icon.grey,
	.status-icon.red,
	.status-icon.green {
		width: var(--status-size, 30px);
		height: var(--status-size, 30px);
	}
</style>
