<template>
	<div class="block-container">
		<div :class="cardBlockClass"
		     class="block-result">
			<div class="block-result_title">
				<icon-status v-if="hasStatus" :color="status" />
				<h2>{{ title }}</h2>
			</div>
			<card-block-result-content>
				<slot></slot>
			</card-block-result-content>
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>
	import {checkStatus, isValidCheckStatus} from '@/utils/checkHelper';
	import IconStatus from '@/components/icons/IconStatus';
	import CardBlockResultContent from '@/components/check/results/CardBlockResultContent';

	export default {
		name: 'CardBlockResult',
		components: {CardBlockResultContent, IconStatus},
		props: {
			title: {
				type: String,
				required: true
			},
			status: {
				type: String,
				validator: value => isValidCheckStatus(value),
				default: checkStatus.GREY.enum
			},
			hasStatus: {
				type: Boolean,
				default: true
			}
		},
		computed: {
			statusColorClass() {
				switch (this.status) {
					case checkStatus.RED.enum:
						return 'red';
					case checkStatus.WHITE.enum:
						return 'white';
					case checkStatus.GREEN.enum:
						return 'green';
					default:
						return 'grey';
				}
			},
			cardBlockClass() {
				return this.hasStatus ? `block-result-${this.statusColorClass}` : ' block-result_no-border block-result_less-padding';
			}
		}
	};
</script>
<style scoped lang="scss">
	@import '@/assets/css/variables';

	.block-result.block-result-white {
		border-left: 2px solid #ffffff;
	}

	.block-result {
		background: #f7f7f7;
		margin: 2px 0;
		border-left: 2px solid $content-color;

		&.block-result_blue {
			background: $blue;
			color: #fff;
		}

		&.block-result_no-border {
			border-left: none;
		}
	}

	.block-result-grey {
		border-color: $lightgrey;

		.block-result_title {
			color: $content-color;
		}

	}

	.block-result-red {
		border-color: $red;

		.block-result_title {
			color: $red;
		}
	}

	.block-result-orange {
		border-color: $orange;

		.block-result_title {
			color: $orange;
		}
	}

	.block-result-green {
		border-color: $green;

		.block-result_title {
			color: $green;
		}
	}

	.block-result_title {

		.status-icon {
			float: left;
			display: block;
			width: 20px;
			height: 20px;
			margin: 10px 10px;
			line-height: 20px;
			text-align: center;

			color: #fff;

			-webkit-border-radius: 10px;
			border-radius: 50%;
			font-size: 8px;

			&.red {
				background: $red;
				font-size: 8px;
			}

			&.green {
				background: $green;
				font-size: 9px;
			}

			&.orange {
				background: $orange;
				font-size: 8px;
			}
		}

		h2 {
			font-size: 20px;
			line-height: 24px;
			margin: 0 40px;
			padding: 8px 0;

			.block-result_less-padding & {
				padding-left: 10px;
				margin-left: 0;
			}

			.block-result_geluid.block-result_less-padding & {
				padding-left: 20px;
			}

			.block-result_no-padding & {
				padding-left: 20px;
				margin-left: 0;
			}

			.block-result_blue & {
				padding-top: 20px;
				font-weight: normal;
			}
		}
	}
</style>
